.header{
	.navbar-default{
		margin: 0;
		background: rgba(#000, 0.75);
		border: none;
		@include border-top-radius(0);
		@include border-bottom-radius(0);

		transition: top 0.2s ease-in-out;
		@include border-top-radius(0);
		@include border-bottom-radius(0);

		&.fixed{
			position: fixed;
			top: 0; bottom: auto;

			-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
		}
		&.not-fixed{
			top: 0 !important;
		}
		&.nav-up{
			top: -60px;

			-webkit-box-shadow: none;
			box-shadow: none;
		}
		&.nav-down{
			top: 0;
		}
		.navbar-search{
			display: none;
			background: $brand-primary;
			color: #fff;

			> div{
				padding: 10px 15px;

				.navbar-form{
					position: relative;
					width: 100%;
					padding: 0;
					margin: 0;
					border: none;
					box-shadow: none;

					.form-group{
						width: 80%;
						margin: 0;
						box-shadow: none;

						.form-control{
							width: 100%;
							background: none;
							border-top: none;
							border-right: none;
							border-bottom: #fff 1px solid;
							border-left: none;
							box-shadow: none;
							@include border-top-radius(0);
							@include border-bottom-radius(0);
							color: #fff;

							&::-webkit-input-placeholder{
								color: #fff;
							}
							&:-moz-placeholder{
								color: #fff;
							}
							&::-moz-placeholder{
								color: #fff;
							}
							&:-ms-input-placeholder{
								color: #fff;
							}
						}
					}
					.button{
						position: absolute;
						top: 50%; right: 0;
						width: 20%;
						font-size: 20px;
						line-height: 40px;
						background: none;
						border: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
						color: #fff;
						-ms-transform: translate(0, -50%);
						-webkit-transform: translate(0, -50%);
						transform: translate(0, -50%);

						&:before{
							content: "\e003";
							position: absolute;
							top: 50%; left: 50%;
							display: inline-block;
							font-family: Glyphicons Halflings;
							font-style: normal;
							font-weight: 400;
							line-height: 1;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							-ms-transform: translate(-50%, -50%);
							-webkit-transform: translate(-50%, -50%);
							transform: translate(-50%, -50%);
						}
						> .btn{
							position: relative;
							z-index: 20;
							display: block;
							@include opacity(0);
						}
					}
				}
			}
		}
		> .container{
			.navbar-header{
				position: relative;
				height: 60px;
				text-align: center;
				background: #fff;

				.navbar-toggle{
					position: absolute;
					top: 50%; right: 20px;
					width: 25px; height: 25px;
					padding: 0;
					margin: 0;
					text-align: center;
					border: $brand-primary 1px solid;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);

					.icon-bar{
						width: 10px; height: 1px;
						margin-left: auto;
						margin-right: auto;
						background: $brand-primary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						&+.icon-bar{
							margin-top: 3px;
						}
					}
					&:hover, &:focus{
						background: none;

						.icon-bar{
							background: $brand-primary;
						}
					}
					&.not-collapsed{
						.icon-bar{
							position: absolute;
							margin: 0;

							&:nth-child(2){
								top: 11px; left: 7px;
								@include rotate(45deg);	
							}
							&:nth-child(3){
								top: 11px; right: 6px;
								@include rotate(-45deg);
							}
							&:nth-child(4){
								display: none;
							}
						}
					}
				}
				.navbar-brand{
					display: inline-block;
					height: auto;
					padding: 10px 20px 5px 20px;

					.img-responsive{
						display: inline-block;
						width: 70px;
					}
					> span{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.438em;
					}
				}
				.navbar-language-toggle{
					position: absolute;
					top: 50%; left: 110px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);

					.btn{
						padding: 10px;
						font-family: 'Lato', sans-serif;
						font-size: 0.813em;
						text-transform: uppercase;
						background: none;
						border: none;
						box-shadow: none;
						color: $brand-secondary;
					}
				}
				.navbar-search-toggle{
					position: absolute;
					top: 50%; right: 130px;
					display: inline-block;
					width: 20px; height: 20px;
					overflow: hidden;
					text-indent: -999px;
					background: url('../images/icon-search.png') 0 0 no-repeat;
					background-size: 20px 20px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
				.navbar-login-toggle{
					position: absolute;
					top: 50%; right: 80px;
					display: inline-block;
					width: 20px; height: 20px;
					overflow: hidden;
					text-indent: -999px;
					background: url('../images/icon-login.png') 0 0 no-repeat;
					background-size: 20px 20px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
					outline: none;
				}
				.navbar-logout-toggle{
					position: absolute;
					top: 50%; right: 80px;
					display: inline-block;
					height: 20px;
					margin-top: -2px;
					font-size: 20px;
					line-height: 1;
					color: $brand-secondary;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
			}
			.navbar-collapse{
				position: relative;
				right: -100%;
				width: 75vw;
				height: 100vh;
				max-height: inherit;
				padding: 15px 20px;
				margin: 0 -15px;
				overflow: auto;
				background: $brand-primary;
				border: none;

				.navbar-nav{
					margin: 0;

					li{
						a{
							font-family: 'Lato', sans-serif;
							font-size: 0.875em;
							font-weight: 400;
							text-transform: uppercase;
							color: #fff;

							&:hover, &:focus{
								background: none;
								color: #fff;
							}
						}
						&.open{
							> a{
								background: none;
								color: #fff;
							}
						}
						&.active{
							> a{
								background: none;
								color: #ccc;
							}
						}
					}
					> li{
						> a{
							padding: 20px 0;
						}
						> ul{
							> li{
								> a{
									padding: 10px 20px;
								}
							}
						}
					}
					&.products-nav{
						li{
							a{
								font-size: 1.000em;
								font-weight: 700;
							}
							&.divider{
								height: 1px;
								background: rgba(#fff, 0.3);
							}
						}
					}
					&.tertiary-nav{
						li{
							a{
								font-size: 0.688em;
							}
						}
					}
				}
				.desktop-navbar{
					> .navbar-nav{
						height: auto !important;
					}
				}
			}
		}
	}

	&.documentation{
		.navbar-default{
			> .container{
				.navbar-header{
					.navbar-back-to-website{
						position: absolute;
						top: 50%; right: 70px;
						display: inline-block;
						font-family: 'Lato', sans-serif;
						font-size: 0.688em;
						font-weight: 400;
						text-transform: uppercase;
						color: $brand-secondary;
						-ms-transform: translate(0, -50%);
						-webkit-transform: translate(0, -50%);
						transform: translate(0, -50%);

						&:hover, &:focus{
							background: none;
							color: $brand-secondary;
						}
					}
					.navbar-logout-toggle{
						right: 30px;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.header{
		.navbar-default{
			height: 141px;
			background: #fff;

			&.nav-up{
				top: -141px;
			}
			&.fixed{
				> .container{
					.navbar-header{
						.navbar-fixed-products{
							display: block;
						}
					}
				}
			}
			> .container{
				background: #fff;

				.navbar-header{
					height: auto;
					z-index: 20;

					.navbar-brand{
						padding: 40px 20px 35px 20px;
						margin-left: -20px;

						.img-responsive{
							width: 115px;
						}
						> span{
							margin-top: 5px;
							font-size: 0.813em;
						}
					}
					.navbar-fixed-products{
						display: none;
						position: absolute;
						top: 50%; left: 150px;
						padding-left: 30px;
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						text-transform: uppercase;
						color: $brand-secondary;
						-ms-transform: translate(0, -50%);
						-webkit-transform: translate(0, -50%);
						transform: translate(0, -50%);

						> span{
							display: block;
							position: absolute;
							top: 50%; left: 0;
							width: 25px; height: 25px;
							padding: 7px 0;
							margin: 0;
							text-align: center;
							border: $brand-secondary 1px solid;
							@include border-top-radius(100%);
							@include border-bottom-radius(100%);
							-ms-transform: translate(0, -50%);
							-webkit-transform: translate(0, -50%);
							transform: translate(0, -50%);

							.icon-bar{
								display: block;
								width: 10px; height: 1px;
								margin-left: auto;
								margin-right: auto;
								background: $brand-secondary;
								@include border-top-radius(0);
								@include border-bottom-radius(0);

								&+.icon-bar{
									margin-top: 3px;
								}
							}
						}
						&:hover, &:focus{
							text-decoration: none;
						}
						&:hover{
							color: $brand-primary;

							> span{
								border-color: $brand-primary;

								.icon-bar{
									background-color: $brand-primary;
								}
							}
						}
						.open{
							.icon-bar{
								position: absolute;
								margin: 0;

								&:nth-child(1){
									top: 11px; left: 7px;
									@include rotate(45deg);	
								}
								&:nth-child(2){
									top: 8px; right: 6px;
									@include rotate(-45deg);
								}
								&:nth-child(3){
									display: none;
								}
							}
						}
					}
				}
				.navbar-collapse{
					right: auto;
					width: auto; height: auto;
					padding: 0;
					margin: 0;
					background: none;

					.navbar-nav{
						li{
							a{
								padding: 15px;
								color: $brand-secondary;

								.caret{
									display: none;
								}
								&:hover, &:focus{
									text-decoration: none;
									color: $brand-primary;
								}
							}
							&.open{
								> a{
									color: $brand-secondary;
									
									&:hover, &:focus{
										text-decoration: none;
										color: $brand-primary;
									}
								}
							}
						}
						> li{
							.dropdown-menu{
								left: 0;
								width: 200px;
								max-width: inherit;
								padding-top: 15px;
								padding-bottom: 15px;
								background: $brand-primary;
								border: none;
								@include border-top-radius(3px);
								@include border-bottom-radius(3px);
								box-shadow: none;

								&:before{
									content: '';
									display: block;
									position: absolute;
									top: -7px; left: 10px;
									width: 20px; height: 20px;
									margin-left: 0;
									background: $brand-primary;
									border-bottom: none;
									@include rotate(45deg);
								}
								> li{
									> a{
										color: #fff;

										&:hover, &:focus{
											color: rgba(#fff, 0.5);
										}
									}
									&.active{
										> a{
											color: #ccc;
										}
									}
								}
							}
						}
						&.primary-nav{
							> li{
								> a{
									padding-left: 10px;
									padding-right: 10px;
									font-size: 0.650em;

									> span{
										display: inline-block;
									}
								}
								&.active{
									> a{
										color: $brand-primary;
									}
								}
								&.made-in-italy{
									> a{
										> span{
											> span{
												position: relative;
												padding-bottom: 5px;
												border-bottom: #eee 2px solid;

												&:before{
													content: '';
													position: absolute;
													top: 100%; left: 0;
													display: block;
													width: 33.3333%; height: 2px;
													background: #009246;
												}
												&:after{
													content: '';
													position: absolute;
													top: 100%; right: 0;
													display: block;
													width: 33.3333%; height: 2px;
													background: #CE2B37;
												}
											}
										}
									}
								}
							}
						}
						&.secondary-nav{
							position: absolute;
							top: 15px; right: -10px;

							> li{
								> a{
									padding-left: 10px;
									padding-right: 10px;

									&:hover{
										/* text-decoration: underline; */
									}
								}
							}
							li{
								> a{
									font-size: 0.588em;
								}
								&.active{
									> a{
										color: $brand-primary;
									}
								}
								&.language-dropdown{
									> a{
										color: $brand-tertiary;

										strong{
											display: inline-block;
											margin-left: 5px;
											font-weight: 400;
											color: $brand-secondary;
										}
										&:hover, &:focus{
											text-decoration: none;
											color: $brand-tertiary;

											strong{
												color: $brand-primary;
											}
										}
									}
								}
								&.user-label{
									> span{
										display: inline-block;
										padding: 15px 0 15px 15px;
										font-family: 'Lato', sans-serif;
										font-size: 0.588em;
										font-weight: 400;
										line-height: 20px;
										text-transform: uppercase;
									}
								}
								&.user-dropdown{
									> a{
										padding-left: 5px;
									}
								}
							}
						}
					}
					.desktop-navbar{
						position: absolute;
						bottom: 15px; right: -10px;

						> .navbar-form{
							position: relative;

							.form-group{
								display: none;
								position: absolute;
								top: 50%; right: 100%;
								-ms-transform: translate(0, -50%);
								-webkit-transform: translate(0, -50%);
								transform: translate(0, -50%);

								.form-control{
									border-top: none;
									border-right: none;
									border-bottom: $brand-secondary 1px solid;
									border-left: none;
									box-shadow: none;
								}
							}
							.btn{
								width: 26px; height: 26px;
								padding: 0;
								overflow: hidden;
								text-indent: -999px;
								background: transparent url('../images/icon-search.png') 0 0 no-repeat;
								background-size: 26px 26px;
								border: none;
								box-shadow: none;
								outline: none;

								&:hover{
									background-image: url('../images/icon-search-red.png');
								}
							}
							&.open{
								.form-group{
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}

	.page-head{
		position: relative;

		> .container{
			position: static;

			> .row{
				> div{
					position: static;
				}
			}
		}
		.inpage-products{
			.cats-list{
				padding: 20px 0;

				> span{
					display: block;
					padding-bottom: 5px;
					font-family: 'Lato', sans-serif;
					font-size: 0.688em;
					font-weight: 300;
					text-transform: uppercase;
					border-bottom: $brand-primary 1px solid;
				}
				> ul{
					padding: 0 0 20px 0;
					margin: 0;
					list-style: none;

					> li{
						margin: 0 -15px;

						> a{
							display: block;
							padding: 10px 15px;
							margin: 10px 0;
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
							text-transform: uppercase;
							border-left: transparent 2px solid;
							color: $brand-secondary;

							&:hover, &:focus{
								text-decoration: none;
							}
							&:hover{
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
						&.active{
							> a{
								border-color: $brand-primary;
							}
						}
					}
				}
			}
			.cats-list2{
				.cats-list{
					> span{
						border-color: #fff;
					}
					> ul{
						> li{
							> a{
								color: #fff;

								&:hover{
									color: #ccc;
									border-color: #ccc;
								}
							}
							&.jsactive{
								> a{
									color: #fff;
									border-color: #fff;
								}
							}
						}
					}
				}
			}
			.subcats-list{
				position: absolute;
				top: 0; left: 0;
				z-index: 980;
				width: 100%;
				min-height: 0;
				max-height: 0;
				overflow: hidden;
				background: $brand-primary;
				color: #fff;
				transition: min-height 0.5s ease-in-out;

				&.no-height{
					height: 0;
				}
				> .container{
					> .row{
						> .row-height{
							> div{
								> .row{
									> .row-height{
										> div{
											> .list{
												padding: 60px 0 30px 0;

												> .row{
													display: none;
												}
												.item{
													margin-bottom: 30px;

													> a{
														display: block;
														text-align: center;
														color: #fff;

														&:hover, &:focus{
															text-decoration: none;
														}
														.img-responsive{
															display: inline-block;
														}
														> span{
															display: block;
															margin-top: 40px;
															font-size: 0.875em;
															font-family: 'Lato', sans-serif;
															text-transform: uppercase;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}	
				}
			}
		}
		.page-label{
			position: relative;
			z-index: 100;

			> span{
				position: absolute;
				top: 354px; left: -328px;
				width: 700px;
				font-family: 'Lato', sans-serif;
				font-size: 4.375em;
				font-weight: 200;
				line-height: 1;
				text-align: right;
				text-transform: uppercase;
				@include rotate(-90deg);
				white-space: nowrap;
			}
		}
	}

	&.documentation{
		.navbar-default{
			> .container{
				.navbar-collapse{
					.navbar-nav{
						&.secondary-nav{
							top: 50%;
							-ms-transform: translate(0, -50%);
							-webkit-transform: translate(0, -50%);
							transform: translate(0, -50%);
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.header{
		.navbar-default{
			> .container{
				.navbar-collapse{
					.navbar-form{
						padding: 0 40px 0 0;
					}
					.navbar-nav{
						> li{
							.dropdown-menu{
								left: 50% !important;
								width: 200px;
								margin-left: -100px;
								font-size: 16px;

								&:before{
									left: 50%;
									margin-left: -10px;
								}
								> li{
									> a{
										font-size: 0.688em;
										white-space: inherit;
									}
								}
							}
						}
						&.primary-nav{
							> li{
								> a{
									padding-left: 20px;
									padding-right: 20px;
									font-size: 0.750em;
								}
							}
						}
						&.secondary-nav{
							> li{
								> a{
									font-size: 0.688em;
								}
								&.user-label{
									> span{
										font-size: 0.688em;
									}
								}
							}
						}
					}
					.desktop-navbar{
						right: -20px;
					}
				}
			}
		}
	}

	.page-head{
		.inpage-products{
			.cats-list{
				> ul{
					> li{
						> a{
							font-size: 0.875em;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.header{
		.navbar-default{
			> .container{
				.navbar-header{
					.navbar-fixed-products{
						left: 180px;
						padding-left: 40px;
						font-size: 0.875em;
					}
				}
				.navbar-collapse{
					.navbar-nav{
						li{
							.dropdown-menu{
								> li{
									> a{
										&:hover{
											color: #fff;
											@include opacity(1);

											> span{
												display: inline-block;
												padding-left: 7px;
												margin-left: -9px;
												border-left: #fff 2px solid;
											}
										}
									}
								}
							}
						}
						&.primary-nav{
							> li{
								> a{
									font-size: 0.875em;
								}
							}
						}
						&.secondary-nav{
							right: -20px;

							> li{
								> a{
									padding-left: 20px;
									padding-right: 20px;
									font-size: 0.688em;
								}
								&.user-label{
									> span{
										font-size: 0.688em;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.page-head{
		.inpage-products{
			.cats-list{
				> span{
					font-size: 0.875em;
				}
				> ul{
					> li{
						> a{
							font-size: 1.000em;
						}
					}
				}
			}
			.subcats-list{
				> .container{
					> .row{
						> .row-height{
							> div{
								> .row{
									> .row-height{
										> div{
											> .list{
												padding-left: 120px;
												padding-right: 120px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.page-head{
		.inpage-products{
			.cats-list{
				> ul{
					> li{
						> a{
							font-size: 1.250em;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.header .navbar-default>.container .navbar-header .navbar-search-toggle{
		background-image: url('../images/icon-search@2x.png');
	}
	.header .navbar-default>.container .navbar-header .navbar-login-toggle{
		background-image: url('../images/icon-login@2x.png');
	}
	.header .navbar-default>.container .navbar-collapse .desktop-navbar>.navbar-form .btn{
		background-image: url('../images/icon-search@2x.png');
	}
	.header .navbar-default>.container .navbar-collapse .desktop-navbar>.navbar-form .btn:hover{
		background-image: url('../images/icon-search-red@2x.png');
	}
}
