.yt-hd-thumbnail-inner-container {
    height: 0;
    padding-top: 56.25%;
    position: relative
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail,
.yt-hd-thumbnail-inner-container>iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: 0
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail {
    z-index: 2
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail img {
    width: 100%
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail.yt-hd-thumbnail-darken:before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .3;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail.yt-hd-thumbnail-darken:hover:before {
    opacity: 0
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail:after {
    display: block;
    position: absolute;
    content: '';
    background-image: url('../images/youtube-play.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    opacity: 1;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail:hover:after {
    opacity: 1
}

.yt-hd-thumbnail-inner-container>iframe {
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity .3s ease .3s;
    -moz-transition: opacity .3s ease .3s;
    transition: opacity .3s ease .3s
}

.yt-hd-thumbnail-inner-container.yt-hd-thumbnail-clicked>a.yt-hd-thumbnail {
    display: none
}

.yt-hd-thumbnail-inner-container.yt-hd-thumbnail-clicked>iframe {
    opacity: 1
}



.yt-hd-thumbnail-inner-container>a>span{
    position: absolute;
    top: 50%; left: 50%;
    margin-top: 40px;
    font-size: 0.813em;
    text-transform: uppercase;
    color: #fff;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (min-width: $screen-md-min) {
    .yt-hd-thumbnail-inner-container>a.yt-hd-thumbnail:after {
        background-position: center center;
        width: 80px;
        height: 80px;
        margin-left: -40px;
        margin-top: -40px;
    }
    .yt-hd-thumbnail-inner-container>a>span{
        margin-top: 80px;
        font-size: 2.125em;
    }
}