.footer{
	padding: 40px 0 20px 0;

	> .container{
		> div{
			padding-top: 30px;
			border-top: #DFDFDF 1px solid;

			.footer-newsletter{
				margin-bottom: 35px;

				.title{
					display: block;
					padding-left: 15px;
					font-size: 0.938em;
					font-weight: 300;
					text-transform: uppercase;
				}
				.subscription-form{
					margin-top: 15px;
					position: relative;
					height: 54px;

					.form-control{
						position: absolute;
						top: 0; left: 0;
						width: 70%; height: 100%;
						padding: 0 22px;
						line-height: 54px;
					}
					.btn{
						position: absolute;
						top: 0; right: 0;
						width: 30%; height: 100%;
					}
				}
			}
			.footer-brand{
				margin-bottom: 35px;
				text-align: center;

				.title{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 1.250em;
					font-weight: 400;
					color: $brand-primary;
				}
				.logo{
					display: block;
					margin-bottom: 10px;
					font-family: 'Lato', sans-serif;
					font-size: 0.570em;

					.img-responsive{
						display: inline-block;
					}
					span{
						display: block;
					}
				}
				p{
					margin: 0;
					font-family: 'Lato', sans-serif;
					font-size: 0.625em;
					letter-spacing: 0.1em;
					line-height: 2;
					text-transform: uppercase;
					color: $brand-tertiary;
				}
				a{
					color: $brand-tertiary;
				}
			}
			.footer-certifications{
				margin-bottom: 35px;

				.img-responsive{
					display: inline-block;
					margin-right: 10px;
					vertical-align: middle;
				}
			}
			.footer-social{
				text-align: right;

				a{
					display: inline-block;
					width: 42px; height: 42px;
					margin-left: 10px;
					line-height: 42px;
					font-size: 18px;
					text-align: center;
					border: #B7BCC0 1px solid;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					color: $brand-secondary;

					&:hover, &:focus{
						text-decoration: none;
					}
					&:hover{
						border-color: $brand-primary;
						color: $brand-primary;
					}
				}
			}
			.footer-legal{
				font-family: 'Lato', sans-serif;
				font-size: 0.563em;
				line-height: 2;
				letter-spacing: 0.1em;
				text-align: center;
				text-transform: uppercase;
				color: $brand-tertiary;

				p{
					margin: 0;
				}
				a{
					display: inline-block;
					margin: 0 5px;
					text-decoration: underline;
					color: $brand-tertiary;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.footer{
		padding: 25px 0;

		> .container{
			> div{
				padding-top: 0;
				border-top: none;

				.footer-newsletter{
					.title{
						line-height: 1;
					}
				}
				.footer-brand{
					text-align: left;
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.footer{
		> .container{
			> div{
				.footer-newsletter{
					margin-bottom: 0;

					.title{
						font-size: 1.250em;
					}
				}
				.footer-brand{
					margin-bottom: 20px;

					.title{
						margin-bottom: 5px;
					}
					p{
						font-size: 0.688em;
					}
				}
				.footer-legal{
					margin-bottom: 0;
					font-size: 0.625em;
				}
				.footer-social{
					margin-bottom: 0;
					text-align: left;

					a{
						margin: 0 10px 0 0;
					}
				}
				.footer-certifications{
					margin-bottom: 0;
					text-align: right;

					.img-responsive{
						margin: 0 0 0 10px;
					}
				}
			}
		}
	}
}
