.contacts{
	> .row{
		margin: 0;

		> div{
			padding: 0;
		}
	}
	.company-info{
		padding: 20px;
		background: $brand-primary;
		color: #fff;

		.title{
			font-family: 'Lato', sans-serif;
			font-size: 1.750em;
			font-weight: 400;
			text-transform: uppercase;
		}
		p{
			margin-bottom: 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.875em;
			font-weight: 300;
			line-height: 2;
			text-transform: uppercase;
		}
		a{
			color: #fff;
		}
	}
	.contact-form{
		padding: 20px;
		background: #F7F7F7;

		h2{
			padding-left: 20px;
			margin: 0 0 20px 0;
			font-size: 1.250em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.form-group{
			margin-bottom: 30px;

			.form-control{
				height: auto;
				padding: 20px;
				background: none;
				border-color: $brand-tertiary;
				color: $brand-secondary;

				&::-webkit-input-placeholder {
					color: $brand-secondary;
				}
				&:-moz-placeholder{
					color: $brand-secondary;
				}
				&::-moz-placeholder{
					color: $brand-secondary;
				}
				&:-ms-input-placeholder{
					color: $brand-secondary;
				}
				&::-ms-input-placeholder{
					color: $brand-secondary;
				}
			}
			.SumoSelect{
				width: 100%;

				> .CaptionCont{
					padding: 20px;
					font-family: 'Lato', sans-serif;
					font-size: 0.875em;
					background: none;
					border-color: $brand-tertiary;
					@include border-top-radius(0);
					@include border-bottom-radius(0);

					> span{
						cursor: pointer;
					}
					> label{
						display: inline-block;
						font-family: 'Glyphicons Halflings';
						font-style: normal;
						font-weight: 400;
						line-height: 1;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;

						&:before{
							position: absolute;
							top: 50%; left: 0;
							content: "\002b";
							-ms-transform: translate(0, -50%);
							-webkit-transform: translate(0, -50%);
							transform: translate(0, -50%);
						}
						i{
							display: none;
						}
					}
					> .search-txt{
						padding: 20px;
						font-family: 'Lato', sans-serif;
						background: none;
						border-color: $brand-tertiary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
					}
				}
				&.open{
					> .CaptionCont{
						> label{
							&:before{
								content: "\e014";
							}
						}
					}
					> .optWrapper{
						top: 59px;
						background: $brand-primary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
						border: none;
						box-shadow: none;

						> .options{
							> li{
								&.opt{
									padding: 10px 20px;
									border: none;
									color: #fff;

									label{
										display: block;
									}
									&.selected{
										color: #ccc;
									}
									&:hover{
										background: none;
										color: #fff;

										label{
											padding-left: 7px;
											margin-left: -9px;
											border-left: #fff 2px solid;
										}
									}
									&.placeholder{
										display: none;
									}
								}
							}
						}
						.no-match{
							padding: 20px;
							color: #fff;
						}
					}
				}
			}
		}
		.checkbox{
			margin-top: 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;

			a{
				color: $brand-secondary;
				text-decoration: underline;
			}
		}
		.btn{
			padding: 20px;
		}
		.contact-intro{
			text-align: center;

			h2{
				padding-left: 0;
			}
			.btn-underlined{
				position: relative;
				padding: 5px 0;
				margin: 15px 30px;
				box-shadow: none;
				color: $brand-secondary;

				&:after{
					content: '';
					position: absolute;
					top: 100%; left: 0;
					width: 100%; height: 2px;
					background: $brand-primary;
				}
				&:hover, &:focus{
					text-decoration: none;
				}
				&:hover, &.active{
					color: $brand-primary;
				}
			}
		}
		.contact-box{
			display: none;
			padding-top: 40px;

			p{
				margin-bottom: 15px;
				line-height: 1.7;
			}
		}
	}
	.contacts-map{
		.page-gmap{
			width: 100%;
			height: 400px;

			> div{
				width: 100%; height: 100%;
			}
			.marker{
				display: none;
			}
		}
	}
	.map-controls{
		padding: 20px;

		> span{
			position: relative;
			display: inline-block;
			padding: 0 0 0 30px;
			margin: 0 20px 20px 0; 
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;
			font-weight: 400;
			line-height: 20px;
			cursor: pointer;

			&:before{
				content: '';
				position: absolute;
				top: 50%; left: 0;
				display: block;
				width: 20px; height: 20px;
				background: transparent center center no-repeat;
				border: $brand-secondary 1px solid;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
			&.active{
				&:before{
					background-image: url('../images/icon-check-checked.png');
					background-size: 16px 12px;
				}
			}
		}
	}
}

.resellers{
	> .row{
		margin: 0;

		> div{
			padding: 0;
		}
		> .row-height{
			> div{
				padding: 0;
			}
		}
	}
	.resellers-info{
		padding: 40px 20px;
		background: $brand-primary;
		color: #fff;

		h1{
			margin: 0 0 20px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.750em;
			font-weight: 400;
			text-transform: uppercase;
		}
		> div{
			font-family: 'Lato', sans-serif;
			font-weight: 400;
			text-transform: uppercase;

			> .title{
				display: block;
				margin-bottom: 5px;
				font-size: 0.875em;
			}
			p{
				font-size: 0.750em;
			}
		}
		a{
			color: #fff;
		}
	}
	.resellers-select{
		padding: 30px;
		background: #F7F7F7;

		h2{
			margin: 0 0 20px 0;
			font-size: 1.250em;
			font-weight: 300;
			text-align: center;
			text-transform: uppercase;
		}
		.form-control{
			height: 54px;
			padding: 16px 22px;
			font-size: 14px;
			background: none;
			border: #9B9B9B 1px solid;
			box-shadow: none;
		}
		.SumoSelect{
			width: 100%;

			> .CaptionCont{
				padding: 20px;
				font-family: 'Lato', sans-serif;
				font-size: 0.875em;
				background: none;
				border-color: $brand-tertiary;
				@include border-top-radius(0);
				@include border-bottom-radius(0);

				> span{
					cursor: pointer;
				}
				> label{
					display: inline-block;
					font-family: 'Glyphicons Halflings';
					font-style: normal;
					font-weight: 400;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;

					&:before{
						position: absolute;
						top: 50%; left: 0;
						content: "\002b";
						-ms-transform: translate(0, -50%);
						-webkit-transform: translate(0, -50%);
						transform: translate(0, -50%);
					}
					i{
						display: none;
					}
				}
				> .search-txt{
					padding: 20px;
					font-family: 'Lato', sans-serif;
					background: none;
					border-color: $brand-tertiary;
					@include border-top-radius(0);
					@include border-bottom-radius(0);
				}
			}
			&.open{
				> .CaptionCont{
					> label{
						&:before{
							content: "\e014";
						}
					}
				}
				> .optWrapper{
					top: 59px;
					background: $brand-primary;
					@include border-top-radius(0);
					@include border-bottom-radius(0);
					border: none;
					box-shadow: none;

					> .options{
						> li{
							&.opt{
								padding: 10px 20px;
								border: none;
								color: #fff;

								label{
									display: block;
								}
								&.selected{
									color: #ccc;
								}
								&:hover{
									background: none;
									color: #fff;

									label{
										padding-left: 7px;
										margin-left: -9px;
										border-left: #fff 2px solid;
									}
								}
								&.placeholder{
									display: none;
								}
							}
						}
					}
					.no-match{
						padding: 20px;
						color: #fff;
					}
				}
			}
		}
	}
	.resellersmap-controls{
		padding: 30px 30px 30px 30px;
		background: #E2E2E2;

		> div{
			> span{
				position: relative;
				display: inline-block;
				padding: 0 0 0 30px;
				margin: 0 20px 20px 0; 
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;
				font-weight: 400;
				line-height: 20px;
				cursor: pointer;

				&:before{
					content: '';
					position: absolute;
					top: 50%; left: 0;
					display: block;
					width: 20px; height: 20px;
					background: transparent center center no-repeat;
					border: $brand-secondary 1px solid;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
				&.active{
					&:before{
						background-image: url('../images/icon-check-checked.png');
						background-size: 16px 12px;
					}
				}
			}
		}
		.filter-map{
			position: absolute;
			bottom: -20px; right: 30px;
			z-index: 100;
			padding: 20px 25px;
		}
	}
	.resellersmap{
		.resellers-gmap{
			width: 100%;
			height: 400px;

			> div{
				width: 100%; height: 100%;
			}
		}
		.marker{
			display: none;
		}
	}
	.reseller-results{
		padding: 30px;
		background: #F7F7F7;

		h3{
			margin: 0 0 30px 0;
			font-size: 1.250em;
			font-weight: 300;
			text-align: center;
			text-transform: uppercase;
		}
		> div{
			> div{
				margin-top: 30px;

				&:first-child{
					margin-top: 0;
				}
				h4{
					margin: 0 0 5px 0;
					font-size: 1em;
					font-weight: 400;
					text-transform: uppercase;
				}
				p{
					margin: 0;
					font-size: 12px;

					a{
						text-decoration: none;
						color: $brand-secondary;
					}
				}
			}
		}
	}
	#reseller_search_results{
		display: none;
	}
}

.careers{
	> .row{
		margin: 0;

		> div{
			padding: 0;
		}
	}

	.careers-title{
		padding: 40px 20px;
		background: $brand-primary;
		color: #fff;

		a{
			color: #fff;
		}
		h1{
			margin: 0 0 40px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.750em;
			font-weight: 400;
			text-transform: uppercase;
		}
		p{
			font-family: 'Lato', sans-serif;
			font-size: 0.875em;
			font-weight: 300;
			line-height: 2;
			text-transform: uppercase;
		}
		.name{
			display: block;
			margin-top: 40px;
			margin-bottom: 10px;
			font-family: 'Lato', sans-serif;
			font-size: 0.875em;
			font-weight: 400;
			text-align: right;
		}
		.position{
			display: block;
			margin-top: 0;
			margin-bottom: 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.875em;
			font-style: italic;
			font-weight: 400;
			text-align: right;
		}
	}
	.open-positions{
		padding: 40px 20px;
		background: #E5E5E5;

		h2{
			margin: 0 0 20px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.250em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.jobs-accordion{
			.panel-group{
				margin: 0;

				.panel-default{
					background: none;
					border-top: none;
					border-right: none;
					border-bottom: #9B9B9B 1px solid;
					border-left: none;
					@include border-top-radius(0);
					@include border-bottom-radius(0);
					box-shadow: none;

					.panel-heading{
						padding: 0;
						background: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						.panel-title{
							a{
								position: relative;
								display: block;
								padding: 20px 40px 20px 20px;
								font-family: 'Lato', sans-serif;
								font-size: 0.875em;
								font-weight: 400;
								text-transform: uppercase;

								&:hover, &:focus{
									text-decoration: none;
								}
								&:after{
									content: '';
									position: absolute;
									top: 50%; right: 20px;
									display: block;
									width: 14px; height: 14px;
									background: url('../images/minus-black.png') 0 center no-repeat;
									background-size: 14px 2px;
									-ms-transform: translate(0, -50%);
									-webkit-transform: translate(0, -50%);
									transform: translate(0, -50%);
								}
								&.collapsed{
									&:after{
										background: url('../images/plus-black.png') 0 0 no-repeat;
										background-size: 14px 14px;
									}
								}
							}
						}
					}
					.panel-collapse{
						.panel-body{
							padding-top: 0;
							border: none;

							p{
								font-size: 1.000em;
								font-weight: 300;
								line-height: 2;
							}
							.job-details{
								margin-top: 30px;

								> div{
									margin: 20px 0;
									font-family: 'Lato', sans-serif;
									font-size: 0.750em;
									font-weight: 400;
									text-transform: uppercase;

									> span{
										color: #9B9B9B;
									}
									&.apply{
										font-size: 1.000em;
										text-align: right;

										.btn{
											padding: 10px 0;
											border-bottom: $brand-primary 2px solid;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.career-form{
		padding: 40px 20px;
		background: #F7F7F7;

		h2{
			margin: 0 0 20px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.250em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.form{
			.form-group{
				margin-bottom: 30px;

				.form-control{
					box-shadow: none;
					height: auto;
					padding: 20px;
					background: none;
					border-color: $brand-tertiary;
					color: #4A4A4A;

					&::-webkit-input-placeholder {
						color: #4A4A4A;
					}
					&:-moz-placeholder{
						color: #4A4A4A;
					}
					&::-moz-placeholder{
						color: #4A4A4A;
					}
					&:-ms-input-placeholder{
						color: #4A4A4A;
					}
					&::-ms-input-placeholder{
						color: #4A4A4A;
					}
				}
				.SumoSelect{
					width: 100%;

					> .CaptionCont{
						padding: 20px;
						font-family: 'Lato', sans-serif;
						font-size: 0.875em;
						background: none;
						border-color: $brand-tertiary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						> span{
							cursor: pointer;
						}
						> label{
							display: inline-block;
							width: 35px;
							font-family: 'Glyphicons Halflings';
							font-style: normal;
							font-weight: 400;
							line-height: 1;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;

							&:before{
								position: absolute;
								top: 50%; left: 0;
								content: "\002b";
								-ms-transform: translate(0, -50%);
								-webkit-transform: translate(0, -50%);
								transform: translate(0, -50%);
							}
							i{
								display: none;
							}
						}
						> .search-txt{
							padding: 20px;
							font-family: 'Lato', sans-serif;
							background: none;
							border-color: $brand-tertiary;
							@include border-top-radius(0);
							@include border-bottom-radius(0);
						}
					}
					&.open{
						> .CaptionCont{
							> label{
								&:before{
									content: "\e014";
								}
							}
						}
						> .optWrapper{
							top: 59px;
							background: $brand-primary;
							@include border-top-radius(0);
							@include border-bottom-radius(0);
							border: none;
							box-shadow: none;

							> .options{
								> li{
									&.opt{
										padding: 10px 20px;
										border: none;
										color: #fff;

										label{
											display: block;
										}
										&.selected{
											color: #ccc;
										}
										&:hover{
											background: none;
											color: #fff;

											label{
												padding-left: 7px;
												margin-left: -9px;
												border-left: #fff 2px solid;
											}
										}
										&.placeholder{
											display: none;
										}
									}
								}
							}
						}
						.no-match{
							padding: 20px;
							color: #fff;
						}
					}
				}
				.custom-file-upload{
					font-size: 1.000em;

					.file-upload-wrapper{
						position: relative;
						background: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						&.file-selected{
							.file-upload-button{
								text-indent: -9999px;
							}
						}
					}
					.file-upload-input{
						padding: 20px;
						width: 100%;
						height: 62px;
						font-size: 1.000em;
						background: #F7F7F7;
						border: $brand-tertiary 1px solid;
						box-shadow: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);

						&:focus{
							box-shadow: none;
							outline: none;
						}
					}
					.file-upload-button{
						position: absolute;
						top: 0; right: 0;
						width: 100%;
						height: 62px;
						overflow: hidden;
						background: none;
						border: none;
						padding: 20px;
						text-align: left;
						font-family: 'Lato', sans-serif;
						font-size: 0.875em;

						&:focus{
							box-shadow: none;
							outline: none;
						}
						&:after{
							content: '';
							position: absolute;
							top: 50%; right: 14px;
							width: 33px; height: 33px;
							background: url('../images/input-file.png') 0 0 no-repeat;
							background-size: 33px 33px;
							-ms-transform: translate(0, -50%);
							-webkit-transform: translate(0, -50%);
							transform: translate(0, -50%);
						}
					}
				}
			}
			.checkbox{
				margin-top: 0;
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;

				a{
					color: #4A4A4A;
					text-decoration: underline;
				}
			}
			.btn{
				padding: 20px;
			}
		}
	}
}

.search-results-top{
	.form{
		position: relative;
		padding: 30px 20px;
		margin: 20px 0;
		background: $brand-primary;
		color: #fff;

		::selection {
			background: #fff;
			color: $brand-primary;
		}
		::-moz-selection {
			background: #fff;
			color: $brand-primary;
		}

		h1{
			margin: 0 0 30px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.250em;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;
		}
		.form-group{
			margin: 0;

			.form-control{
				height: 54px;
				padding: 15px 20px;
				font-family: 'Lato', sans-serif;
				font-size: 0.875em;
				font-weight: 400;
				background: $brand-primary;
				border: #fff 2px solid;
				color: #fff;

				&::-webkit-input-placeholder {
					color: #fff;
				}
				&:-moz-placeholder{
					color: #fff;
					opacity: 1;
				}
				&::-moz-placeholder{
					color: #fff;
					opacity: 1;
				}
				&:-ms-input-placeholder{
					color: #fff;
				}
				&::-ms-input-placeholder{
					color: #fff;
				}
			}
		}
		.btn{
			position: absolute;
			bottom: 30px; right: 20px;
			display: block;
			width: 54px; height: 54px;
			overflow: hidden;
			text-indent: -999px;
			background: #fff url('../images/icon-search-red.png') center center no-repeat;
			background-size: 26px 26px;
			border: none;

			&:hover{
				background-color: #eee;
			}
		}
	}
	h2{
		padding-left: 20px;
		margin: 0 0 20px 0;
		font-family: 'Lato', sans-serif;
		font-size: 1.000em;
		font-weight: 300;
		text-transform: uppercase;

		span{
			color: $brand-primary;
		}
	}
}

.page-breadcrumbs{
	margin: 20px 0;
	font-family: 'Lato', sans-serif;
	font-size: 0.750em;
	font-weight: 400;
	text-transform: uppercase;
	color: $brand-tertiary;

	a{
		color: $brand-tertiary;
	}
	ol{
		padding: 0;
		margin: 0;
		list-style: none;

		li{
			display: inline-block;
		}
	}
}

.about-top{
	h1{
		margin: 0 0 30px 0;
		font-family: 'Lato', sans-serif;
		font-size: 1.250em;
		font-weight: 400;
		text-transform: uppercase;
		color: $brand-primary;
	}

	.about-numbers{
		.item{
			margin-bottom: 30px;

			.number{
				display: block;
				margin-bottom: 10px;
				font-family: 'Lato', sans-serif;
				font-size: 6.875em;
				font-weight: 200;
				line-height: 1;
				color: $brand-primary;
			}
			.caption{
				display: block;
				padding-left: 40px;
				font-family: 'Lato', sans-serif;
				font-size: 1.375em;
				font-weight: 300;
				line-height: 1;
				text-transform: uppercase;
			}
		}
	}

	.about-team{
		> .row{
			margin: 0 -10px;

			> div{
				> div{
					padding: 0 10px;
				}
			}
		}
		.item{
			margin-bottom: 30px;

			.image{
				margin-bottom: 10px;
			}
			.role{
				display: block;
				margin-bottom: 5px;
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;
				font-weight: 300;
			}
			.name{
				display: block;
				margin-bottom: 5px;
				font-family: 'Lato', sans-serif;
				font-size: 0.875em;
				font-weight: 300;
				text-transform: uppercase;
			}
			.email{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;
				font-weight: 300;
			}
		}
	}

	.about-top-image{
		margin: 0 -15px 30px -15px;
	}

	.about-top-text{
		p{
			font-weight: 300;
			line-height: 2;
		}
		blockquote{
			padding: 0;
			margin: 30px 0;
			font-size: 1.250em;
			font-weight: 300;
			line-height: 2;
			border: none;
		}
	}
}

.page-registration{
	margin: 30px 0;

	.text{
		h1{
			margin: 0 0 15px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		p{
			font-weight: 300;
			line-height: 2;
		}
	}
	.form{
		margin-top: 60px;

		.form-group{
			margin-bottom: 30px;

			.form-control{
				box-shadow: none;
				height: auto;
				padding: 20px;
				background: none;
				border-color: $brand-tertiary;
				color: $brand-secondary;

				&::-webkit-input-placeholder {
					color: $brand-secondary;
				}
				&:-moz-placeholder{
					color: $brand-secondary;
				}
				&::-moz-placeholder{
					color: $brand-secondary;
				}
				&:-ms-input-placeholder{
					color: $brand-secondary;
				}
				&::-ms-input-placeholder{
					color: $brand-secondary;
				}
			}
			.SumoSelect{
				width: 100%;

				> .CaptionCont{
					padding: 20px;
					font-family: 'Lato', sans-serif;
					font-size: 0.875em;
					background: none;
					border-color: $brand-tertiary;
					@include border-top-radius(0);
					@include border-bottom-radius(0);

					> span{
						cursor: pointer;
					}
					> label{
						display: inline-block;
						font-family: 'Glyphicons Halflings';
						font-style: normal;
						font-weight: 400;
						line-height: 1;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;

						&:before{
							position: absolute;
							top: 50%; left: 0;
							content: "\002b";
							-ms-transform: translate(0, -50%);
							-webkit-transform: translate(0, -50%);
							transform: translate(0, -50%);
						}
						i{
							display: none;
						}
					}
					> .search-txt{
						padding: 20px;
						font-family: 'Lato', sans-serif;
						background: none;
						border-color: $brand-tertiary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
					}
				}
				&.open{
					> .CaptionCont{
						> label{
							&:before{
								content: "\e014";
							}
						}
					}
					> .optWrapper{
						top: 59px;
						background: $brand-primary;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
						border: none;
						box-shadow: none;

						> .options{
							> li{
								&.opt{
									padding: 10px 20px;
									border: none;
									color: #fff;

									label{
										display: block;
									}
									&.selected{
										color: #ccc;
									}
									&:hover{
										background: none;
										color: #fff;

										label{
											padding-left: 7px;
											margin-left: -9px;
											border-left: #fff 2px solid;
										}
									}
									&.placeholder{
										display: none;
									}
								}
							}
						}
					}
					.no-match{
						padding: 20px;
						color: #fff;
					}
				}
			}
		}
		.checkbox{
			margin-top: 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;

			a{
				color: $brand-secondary;
				text-decoration: underline;
			}
		}
		.btn{
			padding: 20px;
		}
	}
}

.about-numbers{
	margin: 30px 0 0 0;

	> .row{
		> div{
			margin-bottom: 40px;
			text-align: center;

			.icon{
				margin-bottom: 20px;

				.img-responsive{
					display: inline-block;
				}
			}
			.number{
				display: block;
				margin-bottom: 10px;
				font-family: 'Lato', sans-serif;
				font-size: 1.875em;
				font-weight: 300;
				text-transform: uppercase;
				color: $brand-primary;
			}
			.caption{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 1.000em;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
	}
}

.about-timeline{
	h2{
		margin: 0 0 30px 0;
		font-family: 'Lato', sans-serif;
		font-size: 1.250em;
		font-weight: 400;
		text-transform: uppercase;
		color: $brand-primary;
	}
	.item{
		position: relative;
		padding-left: 75px;
		margin-bottom: 30px;
		overflow: hidden;

		.year{
			position: absolute;
			top: 0; left: 0;
			color: $brand-primary;

			> span{
				position: absolute;
				top: 325px; left: -325px;
				width: 700px;
				font-family: 'Lato', sans-serif;
				font-size: 3.125em;
				font-weight: 200;
				line-height: 1;
				text-align: right;
				text-transform: uppercase;
				@include rotate(-90deg);
				white-space: nowrap;
			}
		}
		.caption{
			margin-top: 30px;
			font-weight: 300;

			.image{
				margin-top: 30px;
			}
		}
	}
}

.about-valori{
	position: relative;
	padding: 60px 20px 60px 75px;
	margin: 30px 0;
	background: transparent center center no-repeat;
	background-size: cover;

	.caption{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: -15px; left: -55px;
			display: block;
			width: 43px; height: 95px;
			background: url('../images/quote-1.png') 0 0 no-repeat;
			background-size: 43px 95px;
		}
		&:after{
			content: '';
			position: absolute;
			bottom: -22px; right: 100px;
			display: block;
			width: 19px; height: 22px;
			background: url('../images/quote-2.png') 0 0 no-repeat;
			background-size: 19px 22px;
		}
		h2{
			margin: 0 0 40px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		p{
			font-size: 1.250em;
			font-weight: 300;
			line-height: 2;
		}
	}
}

.box-documentation{
	.doc-filers{
		padding: 20px;
		margin-bottom: 20px;
		background: $brand-primary;
		color: #fff;

		::selection {
			background: #fff;
			color: $brand-primary;
			@include opacity(1);
		}
		::-moz-selection {
			background: #fff;
			color: $brand-primary;
			@include opacity(1);
		}
		h1{
			margin: 0 0 30px 0;
			font-size: 1.250em;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;
		}
		.form{
			position: relative;
			padding: 0;
			margin: 20px 0;
			background: $brand-primary;
			color: #fff;

			.form-group{
				margin: 0;

				.form-control{
					height: 54px;
					padding: 15px 20px;
					font-family: 'Lato', sans-serif;
					font-size: 0.875em;
					font-weight: 400;
					background: $brand-primary;
					border: #fff 2px solid;
					color: #fff;

					&::-webkit-input-placeholder {
						color: #fff;
					}
					&:-moz-placeholder{
						color: #fff;
						opacity: 1;
					}
					&::-moz-placeholder{
						color: #fff;
						opacity: 1;
					}
					&:-ms-input-placeholder{
						color: #fff;
					}
					&::-ms-input-placeholder{
						color: #fff;
					}
				}
			}
			.btn{
				position: absolute;
				bottom: 0px; right: 0px;
				display: block;
				width: 54px; height: 54px;
				overflow: hidden;
				text-indent: -999px;
				background: #fff url('../images/icon-search-red.png') center center no-repeat;
				background-size: 26px 26px;
				border: none;

				&:hover{
					background-color: #eee;
				}
			}
		}
		.filters{
			> label{
				position: relative;
				display: inline-block;
				padding-left: 30px;
				margin: 0 15px 15px 0;
				font-family: 'Lato', sans-serif;
				font-size: 0.875em;
				font-weight: 400;
				line-height: 20px;

				&:before{
					content: '';
					position: absolute;
					top: 0; left: 0;
					z-index: 10;
					display: block;
					width: 20px; height: 20px;
					padding: 0;
					margin: 0;
					border: #fff 1px solid;
				}
				input{
					position: absolute;
					top: 0; left: 0;
					z-index: 20;
					display: block;
					width: 20px; height: 20px;
					padding: 0;
					margin: 0;
					@include opacity(0);
				}
				&.checked{
					&:before{
						background: url('../images/icon-check-checked-white.png') center center no-repeat;
						background-size: 16px 12px;
					}
				}
			}
		}
	}

	.doc-notification{
		padding: 20px;
		background: #F7F7F7;

		> .caption{
			position: relative;
			padding-left: 35px;
			font-family: 'Lato', sans-serif;
			font-size: 0.875em;
			font-weight: 400;
			text-transform: uppercase;

			&:before{
				content: '';
				position: absolute;
				top: 0; left: 0;
				display: block;
				width: 26px; height: 28px;
				background: url('../images/icon-exclamation.png') center center no-repeat;
				background-size: 26px 28px;
			}
			p{
				margin: 0;
			}
		}
		> .button{
			margin-top: 20px;
			text-align: right;

			.btn{
				padding: 5px 10px;
				font-size: 0.688em;
				font-weight: 400;
				border: $brand-secondary 2px solid;
			}
		}
	}

	.doc-table{
		.table-filters{
			padding: 20px;
			text-align: center;
			background: $brand-secondary;
			color: #fff;

			.dropdown{
				display: inline-block;

				.dropdown-menu{
					width: 100%;
					margin: 0;
					background: $brand-primary;
					border: none;
					@include border-top-radius(0);
					@include border-bottom-radius(0);
					box-shadow: none;

					> li{
						margin: 10px 0;

						&.active{
							> a{
								background: none;
								color: #ccc;
							}
						}
						> a{
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
							font-weight: 400;
							text-transform: uppercase;
							color: #fff;
							white-space: normal;

							&:hover, &:focus{
								background: none;
								color: #fff;

								span{
									display: inline-block;
									padding-left: 7px;
									margin-left: -9px;
									border-left: #fff 2px solid;
								}
							}
						}
					}
				}
			}
			.btn{
				position: relative;
				padding-right: 45px;
				font-size: 0.750em;
				font-weight: 400;
				background: none;
				border: none;
				color: #fff;

				&:after{
					content: '';
					position: absolute;
					top: 50%; right: 15px;
					display: block;
					width: 24px; height: 24px;
					background: url('../images/icon-order.png') center center no-repeat;
					background-size: 18px 12px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
			}
		}
		.table{
			thead{
				tr{
					th{
						padding: 20px 15px;
						font-family: 'Lato', sans-serif;
						font-size: 0.875em;
						font-weight: 700;
						text-transform: uppercase;
						color: #fff;
						background: $brand-secondary;
						border: none;
						color: #fff;

						&.item-size, &.item-date, &.item-link, &.item-preview{
							text-align: center;
						}
					}
				}
			}
			tbody{
				tr{
					td{
						padding: 20px 15px;
						font-family: 'Lato', sans-serif;
						font-size: 1.000em;
						border-bottom: none;
						border-right: none;
						border-top: #D9D9D9 1px solid;
						border-left: none;
						vertical-align: middle;

						&.item-size, &.item-date, &.item-link, &.item-preview{
							text-align: center;
						}
						.btn{
							padding: 5px 10px;
							font-size: 0.688em;
							font-weight: 400;
							border: $brand-primary 2px solid;
						}
						.item-name{
							display: block;
							margin-bottom: 5px;
							font-family: 'Lato', sans-serif;
							font-size: 0.813em;
							font-weight: 700;
							line-height: 1;
						}
						.item-category{
							display: block;
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
						}
						.item-size{
							display: inline-block;
							margin-right: 10px;
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
							font-size: 0.625em;
						}
						.item-date{
							display: inline-block;
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
							font-size: 0.625em;
						}
						.item-preview-mobile{
							margin-top: 15px;
							text-align: center;

							.img-responsive{
								display: inline-block;
							}
						}
						&.item-preview{
							.img-responsive{
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.search-results-top{
		.form{
			margin-bottom: 50px;

			h1{
				margin-bottom: 50px;
				font-size: 3.125em;
				text-align: left;
			}
		}
		h2{
			font-size: 2.125em;
		}
	}

	.search-results-list{
		margin-top: 0;
	}

	.about-top{
		.about-team{
			> .row{
				margin: 0 -20px;

				> div{
					> div{
						padding: 0 20px;
					}
				}
			}
			.item{
				margin-bottom: 40px;

				&.item-1{
					margin-top: 40px;
				}
				&.item-3{
					margin-top: 80px;
				}
			}
		}

		.about-top-image{
			margin: 0 0 30px 0;
		}
	}

	.about-valori{
		padding: 110px 0;
		margin: 80px 0;
	}
}

@media (min-width: $screen-md-min) {
	.contacts{
		margin-bottom: 60px;

		.company-info{
			height: 425px;
			padding: 20px;

			h1{
				margin: 0 0 150px 0;
				font-family: 'Lato', sans-serif;
				font-size: 3.125em;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
		.contact-form{
			padding: 60px 75px;
			margin-top: -222px;
		}
		.contacts-map, .map-controls{
			width: calc(100% + 170px);
			margin-left: -170px;
		}
		.contacts-map{
			.page-gmap{
				height: 600px;
			}
		}
		.map-controls{
			padding: 40px 20px;
			text-align: center;
		}
	}

	.resellers{
		.resellers-info{
			padding: 60px 40px;

			h1{
				margin-bottom: 160px;
				font-weight: 300;
			}
			> div{
				p{
					font-weight: 300;
				}
			}
		}
		.resellers-image{
			width: 100%; height: calc(100% - 140px);
			background: transparent center center no-repeat;
			background-size: cover;
		}
		.resellersmap-controls{
			position: relative;
			height: 140px;

			> div{
				position: absolute;
				top: 50%; left: 0;
				padding: 20px 30px 0 30px;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
		}
		.resellersmap{
			.resellers-gmap{
				width: 120%; height: 600px;
				margin-left: -20%;
			}
		}
		.reseller_search_container{
			background: #f7f7f7;
		}
		#reseller_search_map{
			&.col-md-5{
				.resellersmap{
					.resellers-gmap{
						width: 150%;
						margin-left: -50%;
						background: #f7f7f7;
					}
				}
			}
		}
		#reseller_search_results{
			.reseller-results{
				> div{
					height: 496px;
					overflow: auto;
				}
			}
		}
	}

	.about-top{
		.about-top-image{
			margin: 0 0 50px 0;
		}
	}

	.box-documentation{
		.doc-notification{
			position: relative;
			padding: 60px;

			> .caption{
				padding-left: 65px;
				padding-right: 200px;
				font-size: 1.000em;

				p{
					margin: 0;
				}
				&:before{
					top: 50%;
					width: 52px; height: 55px;
					background-size: 52px 55px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
			}
			> .button{
				position: absolute;
				top: 50%; right: 60px;
				margin-top: 0;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
		}
		.doc-table{
			.table-filters{
				text-align: right;
			}
			.table{
				border: none;

				tbody{
					tr{
						td{
							&.item-category, &.item-size, &.item-date, &.item-link{
								border-right: #d9d9d9 1px solid;
							}
							&.item-category, &.item-size, &.item-date{
								font-size: 0.750em;
							}
							&.item-category{
							}
							&.item-size, &.item-date, &.item-link, &.item-preview{
								width: 130px;
							}
							.item-name{
								margin: 0;
								font-size: 0.875em;
							}
						}
					}
				}
			}
		}
		.doc-sidebar{
			ul{
				padding: 0;
				margin: 0;
				list-style: none;

				li{
					a{
						display: block;
					}
				}
			}
			> ul{
				> li{
					&.has-dropdown{
						/* level 1 */
						> a{
							position: relative;
							padding-right: 45px;
							margin: 10px 0;
							
							.icon-plus{
								content: '';
								position: absolute;
								top: 50%; right: 7px;
								display: block;
								width: 22px; height: 22px;
								background: url('../images/plus-black.png') center center no-repeat;
								background-size: 14px 14px;
								-ms-transform: translate(0, -50%);
								-webkit-transform: translate(0, -50%);
								transform: translate(0, -50%);

								&:hover{
									background-color: #eee;
								}
							}
							&:after{
								content: '';
								position: absolute;
								top: 0; left: 0;
								display: block;
								width: 2px; height: 100%;
							}
							&:hover{
								&:after{
									background: $brand-primary;
								}
							}
						}
						&.active{
							> a{
								&:after{
									background: $brand-primary;
								}
							}
						}
						&.open{
							> a{
								.icon-plus{
									background-image: url('../images/minus-black.png');
									background-size: 14px 2px;
								}
							}
						}
						> ul{
							display: none;
						}
					}
					> a{
						padding: 10px 15px;
						font-family: 'Lato', sans-serif;
						font-size: 1.250em;
						font-weight: 400;
						text-transform: uppercase;
						border-left: transparent 2px solid;
						color: $brand-secondary;

						&:hover, &:focus{
							text-decoration: none;
						}
						&:hover{
							border-color: $brand-primary;
							color: $brand-primary;
						}
					}
					&.active{
						> a{
							border-color: $brand-primary;
							/* color: $brand-primary; */
						}
					}
					&.has-dropdown{
						> a{
							border-color: #fff;
						}
					}

					> ul{
						> li{
							/* level 2 */
							> a{
								padding: 10px 15px;
								font-family: 'Lato', sans-serif;
								font-size: 0.875em;
								font-weight: 400;
								text-transform: uppercase;
								color: $brand-secondary;

								&:hover, &:focus{
									text-decoration: none;
								}
								&:hover{
									text-decoration: underline;
									color: $brand-primary;
								}
							}
							&.active{
								> a{
									color: $brand-primary;
								}
							}
							&.has-dropdown{
								> a{
									position: relative;
									padding-right: 45px;

									.icon-plus{
										content: '';
										position: absolute;
										top: 50%; right: 7px;
										display: block;
										width: 22px; height: 22px;
										background: url('../images/plus-black.png') center center no-repeat;
										background-size: 14px 14px;
										-ms-transform: translate(0, -50%);
										-webkit-transform: translate(0, -50%);
										transform: translate(0, -50%);

										&:hover{
											background-color: #eee;
										}
									}
								}
								&.open{
									> a{
										.icon-plus{
											background-image: url('../images/minus-black.png');
											background-size: 14px 2px;
										}
									}
								}
							}

							> ul{
								display: none;
								margin-left: 20px;

								> li{
									/* level 3 */
									> a{
										padding: 10px 15px;
										font-family: 'Lato', sans-serif;
										font-size: 0.825em;
										font-weight: 400;
										text-transform: uppercase;
										color: $brand-secondary;

										&:hover, &:focus{
											text-decoration: none;
										}
										&:hover{
											text-decoration: underline;
											color: $brand-primary;
										}
									}
									&.active{
										> a{
											color: $brand-primary;
										}
									}
									ul{
										display: none;
										margin-left: 20px;

										> li{
											/* level 4+ */
											> a{
												padding: 10px 15px;
												font-family: 'Lato', sans-serif;
												font-size: 0.750em;
												font-weight: 400;
												text-transform: uppercase;
												color: $brand-tertiary;

												&:hover, &:focus{
													text-decoration: none;
												}
												&:hover{
													text-decoration: underline;
													color: $brand-primary;
												}
											}
											&.active{
												> a{
													color: $brand-primary;
												}
											}
											&.has-dropdown{
												> a{
													position: relative;
													padding-right: 45px;

													.icon-plus{
														content: '';
														position: absolute;
														top: 50%; right: 7px;
														display: block;
														width: 22px; height: 22px;
														background: url('../images/plus-black.png') center center no-repeat;
														background-size: 14px 14px;
														-ms-transform: translate(0, -50%);
														-webkit-transform: translate(0, -50%);
														transform: translate(0, -50%);
														@include opacity(0.5);

														&:hover{
															background-color: #ccc;
														}
													}
												}
												&.open{
													> a{
														.icon-plus{
															background-image: url('../images/minus-black.png');
															background-size: 14px 2px;
														}
													}
												}
											}
										}
									}
									&.has-dropdown{
										> a{
											position: relative;
											padding-right: 45px;

											.icon-plus{
												content: '';
												position: absolute;
												top: 50%; right: 7px;
												display: block;
												width: 22px; height: 22px;
												background: url('../images/plus-black.png') center center no-repeat;
												background-size: 14px 14px;
												-ms-transform: translate(0, -50%);
												-webkit-transform: translate(0, -50%);
												transform: translate(0, -50%);

												&:hover{
													background-color: #eee;
												}
											}
										}
										&.open{
											> a{
												.icon-plus{
													background-image: url('../images/minus-black.png');
													background-size: 14px 2px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.about-valori{
		.caption{
			h2{
				font-size: 3.125em;
				font-weight: 300;
			}
		}
	}

	.about-numbers{
		> .row{
			&:nth-child(odd){
				padding-right: 10%;
			}
			&:nth-child(even){
				padding-left: 20%;
			}
			> div{
				margin-bottom: 100px;

				.number{
					font-size: 2.375em;
				}
				.caption{
					font-size: 1.250em;
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.contacts{
		.company-info{
			height: 485px;
			padding: 50px 40px;
		}
		.contact-form{
			margin-top: -203px;
		}
	}

	.resellers{
		.resellers-info{
			padding: 60px 40px;

			h1{
				font-size: 2.375em;
			}
			> div{
				> .title{
					font-size: 1.375em;
				}
				p{
					font-size: 1.000em;
				}
			}
		}
		.resellers-select{
			padding: 60px;

			.form-group{
				margin: 0;
			}
		}
		.resellersmap-controls{
			padding: 60px;

			> div{
				padding: 60px;
			}
			.filter-map{
				right: 60px;
			}
		}
		#reseller_search_results{
			.reseller-results{
				padding: 60px;

				> div{
					height: 458px;
				}
			}
		}
	}

	.careers{
		.careers-title{
			padding: 60px 40px;

			h1{
				font-size: 3.125em;
				font-weight: 300;
			}
			p{
				font-size: 1.000em;
			}
		}
		.open-positions{
			padding: 60px 75px;

			h2{
				margin-bottom: 60px;
			}
		}
		.career-form{
			padding: 60px 75px;

			h2{
				margin-bottom: 60px;
			}
		}
	}

	.about-top{
		h1{
			margin-bottom: 60px;
			font-size: 3.125em;
			font-weight: 300;
		}

		.about-numbers{
			.item{
				padding: 60px;
				margin-bottom: 0;

				.number{
					margin: 0;
					font-size: 13.750em;
					line-height: 1;
					text-align: right;
				}
				.caption{
					font-size: 2.500em;
					line-height: 1;
				}

				&.border-top{
					border-top: $brand-tertiary 1px solid;
				}
				&.border-right{
					border-right: $brand-tertiary 1px solid;
				}
				&.border-left{
					border-left: $brand-tertiary 1px solid;
				}
				&.border-bottom{
					border-bottom: $brand-tertiary 1px solid;
				}
			}
		}

		.about-team{
			.item{
				.image{
					margin-bottom: 25px;
				}
				.role{
					padding-left: 60px;
				}
				.name{
					padding-left: 60px;
					font-size: 1.500em;
				}
				.email{
					padding-left: 60px;
				}
			}
		}
	}

	.about-timeline{
		margin: 40px 0 90px 0;

		h2{
			margin-bottom: 60px;
			font-size: 3.125em;
			font-weight: 300;
		}
		.item{
			padding-left: 120px;
			margin-bottom: 60px;
			
			.year{
				> span{
					top: 315px; left: -315px;
					font-size: 4.375em;
				}
			}
		}
	}

	.search-results-top{
		.form{
			padding: 50px 60px;

			h1{
				padding-left: 20px;
			}
			.btn{
				bottom: 50px; right: 60px;
				width: auto;
				padding-left: 30px;
				padding-right: 60px;
				text-indent: 0;
				background-position: 90% center;
				color: $brand-primary;
			}
		}
		h2{
			padding-left: 80px;
		}
	}

	.box-documentation{
		.doc-filers{
			padding: 45px 60px;
			margin-bottom: 30px;

			h1{
				padding: 0 20px;
				margin: 0 0 30px 0;
				font-size: 3.125em;
				font-weight: 300;
				text-align: left;
			}
			.form{
				.btn{
					width: auto;
					padding-left: 30px;
					padding-right: 60px;
					text-indent: 0;
					background-position: 90% center;
					color: $brand-primary;
				}
			}
		}
	}

	.page-registration{
		.text{
			h1{
				font-size: 3.125em;
				font-weight: 300;
			}
		}
	}
}

@media (min-width: 1500px) {
	.contacts{
		.contact-form{
			margin-top: -152px;
		}
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.contacts .map-controls>span.active:before{
		background-image: url('../images/icon-check-checked@2x.png');
	}

	.box-documentation .doc-filers .filters>label.checked:before{
		background-image: url('../images/icon-check-checked-white@2x.png');
	}

	.search-results-top .form .btn,
	.box-documentation .doc-filers .form .btn{
		background-image: url('../images/icon-search-red@2x.png');
	}

	.box-documentation .doc-notification>.caption:before{
		background-image: url('../images/icon-exclamation@2x.png');
	}

	.box-documentation .doc-table .table-filters .btn:after{
		background-image: url('../images/icon-order@2x.png');
	}

	.careers .open-positions .jobs-accordion .panel-group .panel-default .panel-heading .panel-title a:after{
		background-image: url('../images/minus-black@2x.png');
	}
	.careers .open-positions .jobs-accordion .panel-group .panel-default .panel-heading .panel-title a.collapsed:after{
		background-image: url('../images/plus-black@2x.png');
	}

	.careers .career-form .form .form-group .custom-file-upload .file-upload-button:after{
		background-image: url('../images/input-file@2x.png');
	}

	.resellers .resellersmap-controls>div>span.active:before{
		background-image: url('../images/icon-check-checked@2x.png');
	}

	.about-valori .caption:before{
		background-image: url('../images/quote-1@2x.png');
	}
	.about-valori .caption:after{
		background-image: url('../images/quote-2@2x.png');
	}
}
