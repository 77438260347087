.home-slider{
	margin-bottom: 20px;

	.slick{
		.slider-item{
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 20;
				display: block;
				width: 100%; height: 100%;
				@include opacity(0.5);
			}
			&.caption-right{
				&.overlay-light{
					&:before{
						background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.65) 100%);
						background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%);
						background: linear-gradient(135deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#a6ffffff',GradientType=1 );
					}
				}
				&.overlay-dark{
					&:before{
						background: -moz-linear-gradient(-45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
						background: -webkit-linear-gradient(-45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
						background: linear-gradient(135deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
					}
				}
			}
			&.caption-left{
				&.overlay-light{
					&:before{
						background: -moz-linear-gradient(45deg, rgba(255,255,255,0.65) 0%, rgba(255,255,255,0) 100%);
						background: -webkit-linear-gradient(45deg, rgba(255,255,255,0.65) 0%,rgba(255,255,255,0) 100%);
						background: linear-gradient(45deg, rgba(255,255,255,0.65) 0%,rgba(255,255,255,0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6ffffff', endColorstr='#00ffffff',GradientType=1 );
					}
				}
				&.overlay-dark{
					&:before{
						background: -moz-linear-gradient(45deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
						background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
						background: linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
					}
				}
			}
			.img-resposnive{
				width: 100%;
				max-width: inherit;
			}
			.caption{
				position: absolute;
				bottom: 45px; left: 0;
				z-index: 20;
				width: 100%;
				padding: 0 15px;

				.title{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 1.625em;
					font-weight: 300;
					text-transform: uppercase;
				}
				p{
					margin: 0;
					font-family: 'Lato', sans-serif;
					font-size: 0.688em;
					text-transform: uppercase;
				}
				.btn{
					padding: 0 0 5px 0;
					margin-top: 12px;
					font-size: 0.688em;
					border-top: none;
					border-right: none;
					border-left: none;
					border-bottom: $brand-primary 2px solid;
					color: $brand-secondary;

					&:hover, &:focus{
						text-decoration: none;
					}
					&:hover{
						border-top: none;
						border-right: none;
						border-left: none;
						color: $brand-primary;
					}
				}
			}
			&.overlay-dark{
				color: #fff;

				.caption{
					.btn{
						color: #fff;

						&:hover{
							color: $brand-primary;
						}
					}
				}
			}
		}
		.slick-dots{
			position: absolute;
			bottom: 15px; left: 50%;
			-ms-transform: translate(-50%, 0);
			-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);
			height: 10px;
			padding: 10px 0;
			margin: 0;
			list-style: none;
			text-align: center;

			> li{
				display: inline-block;

				button{
					display: block;
					width: 10px; height: 10px;
					overflow: hidden;
					padding: 0;
					margin: 0 7px;
					text-indent: -999px;
					background: #fff;
					border: transparent 1px solid;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					outline: none;
				}
				&.slick-active{
					button{
						background: $brand-primary;
					}
				}
			}
		}
	}
}

.home-boxes{
	margin-bottom: -20px;

	.item{
		margin-bottom: 20px;

		&.item-product{
			> a{
				display: block;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				.image{
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.caption{
					padding: 10px;
					border: #DFDFDF 1px solid;

					.title{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 1.125em;
						font-weight: 300;
						line-height: 1;
						text-transform: uppercase;
					}
					p{
						margin: 5px 0 0 0;
						font-family: 'Lato', sans-serif;
						font-size: 0.688em;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
			}
		}
		&.item-case-study{
			> a{
				display: block;
				position: relative;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:before{
					content: '';
					position: absolute;
					bottom: 0; left: 0;
					z-index: 10;
					display: block;
					width: 100%; height: 75%;
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
				}
				.image{
					overflow: hidden;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.lbl{
					position: absolute;
					top: 0; right: 0;
					z-index: 15;
					padding: 5px;
					font-family: 'Lato', sans-serif;
					font-size: 0.625em;
					font-weight: 300;
					text-transform: uppercase;
					background: #fff;
				}
				.caption{
					position: absolute;
					bottom: 0; left: 0;
					z-index: 20;
					width: 100%;
					padding: 20px;
					border: none;
					color: #fff;

					.title{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						font-weight: 400;
						line-height: 1;
						text-transform: uppercase;
					}
					.architect{
						display: block;
						margin: 5px 0 0 0;
						font-family: 'Lato', sans-serif;
						font-size: 0.688em;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
			}
		}
		&.item-magazine{
			> a{
				display: block;
				position: relative;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				.image{
					overflow: hidden;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.caption{
					padding: 10px;

					.category{
						display: block;
						margin-bottom: 5px;
						font-family: 'Lato', sans-serif;
						font-size: 0.625em;
						font-weight: 300;
						text-transform: uppercase;
					}
					.title{
						display: block;
						margin-bottom: 5px;
						font-family: 'Lato', sans-serif;
						font-size: 0.875em;
						font-weight: 300;
						text-transform: uppercase;
					}
					p{
						margin: 0 0 5px 0;
						font-size: 0.750em;
						font-weight: 300;
					}
					.date{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.625em;
						font-style: italic;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
			}
		}
		&.item-new-products{
			.lbl{
				display: block;
				padding-left: 10px;
				margin-bottom: 5px;
				font-family: 'Lato', sans-serif;
				font-size: 0.625em;
				font-weight: 300;
				text-transform: uppercase;
			}
			.slider{
				.slick{
					.slider-item{
						> a{
							position: relative;
							display: block;
							padding: 15px;
							color: $brand-secondary;
							padding-bottom: 30px;

							&:hover, &:focus{
								text-decoration: none;
							}
							&:before{
								content: '';
								position: absolute;
								top: 0; left: 0;
								z-index: 100;
								display: block;
								width: 100%; height: 100%;
								background: rgba(#000, 0.03);
							}
							.image{
								margin-bottom: 15px;
								text-align: center;

								.img-responsive{
									display: inline-block;
								}
							}
							.title{
								display: block;
								font-family: 'Lato', sans-serif;
								font-size: 0.813em;
								font-weight: 300;
								text-transform: uppercase;
							}
							.subtitle{
								display: block;
								font-family: 'Lato', sans-serif;
								font-size: 0.563em;
								font-weight: 400;
							}
						}
					}
					.slick-dots{
						position: absolute;
						bottom: 15px; left: 50%;
						-ms-transform: translate(-50%, 0);
						-webkit-transform: translate(-50%, 0);
						transform: translate(-50%, 0);
						height: 10px;
						padding: 10px 0;
						margin: 0;
						list-style: none;
						text-align: center;

						> li{
							display: inline-block;

							button{
								display: block;
								width: 10px; height: 10px;
								overflow: hidden;
								padding: 0;
								margin: 0 7px;
								text-indent: -999px;
								background: none;
								border: $brand-secondary 1px solid;
								@include border-top-radius(100%);
								@include border-bottom-radius(100%);
								outline: none;
							}
							&.slick-active{
								button{
									border-color: $brand-primary;
								}
							}
						}
					}
				}
			}
		}
		&.item-highlight{
			.caption{
				padding: 10px;
				border: #DFDFDF 1px solid;

				.title{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 1.000em;
					font-weight: 300;
					text-transform: uppercase;
				}
				p{
					margin: 0;
					font-family: 'Lato', sans-serif;
					font-size: 0.688em;
					font-weight: 300;
					text-transform: uppercase;
				}
				.btn{
					padding: 0 0 8px 0;
					margin-top: 10px;
					font-size: 0.688em;
					border-bottom: $brand-primary 2px solid;
					color: $brand-secondary;

					&:hover, &:focus{
						text-decoration: none;
					}
				}
			}
		}
	}
	.mobile-categories{
		> div{
			> div{
				margin-bottom: 20px;

				.title{
					display: block;
					margin-bottom: 20px;
					font-family: 'Lato', sans-serif;
					font-size: 1.000em;
					font-weight: 300;
					text-transform: uppercase;
				}
				> div{
					margin: 0 -15px;

					> a{
						position: relative;
						display: inline-block;
						padding: 0 15px 10px 15px;
						margin-bottom: 10px;
						text-align: center;
						color: $brand-secondary;

						&:hover, &:focus{
							text-decoration: none;
						}
						.images{
							.img-responsive{
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.home-slider{
		margin-bottom: 40px;

		.slick{
			.slider-item{
				.caption{
					bottom: 60px;
					padding: 0 30px;
				}
			}
			.slick-dots{
				/*
				position: absolute;
				bottom: 0; right: 100%;
				height: auto;
				width: 12px;
				margin: 0 10px 10px 0;
				*/
				height: 38px;
				padding: 14px 0;

				> li{
					button{
						width: 10px; height: 10px;
						margin: 0 8px;
						font-family: 'Lato', sans-serif;
						text-align: center;
						color: $brand-secondary;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
							border-color: $brand-primary;
							color: $brand-primary;
						}
					}
				}
			}
		}
	}

	.home-boxes{
		margin-bottom: 100px;

		> .container{
			> .row{
				margin-left: -20px;
				margin-right: -20px;
				
				> div{
					> div{
						padding-left: 20px;
						padding-right: 20px;
					}
				}
			}
		}
		.item{
			&.item-product{
				> a{
					position: relative;

					&:before{
						content: '';
						position: absolute;
						bottom: 0; left: 0;
						z-index: 10;
						display: block;
						width: 100%; height: 75%;
						background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
						background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
						background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
					}
					.image{
						overflow: hidden;
					}
					.caption{
						position: absolute;
						bottom: 0; left: 0;
						z-index: 20;
						width: 100%;
						padding: 20px;
						border: none;
						color: #fff;

						.title{
							font-size: 1.250em;
						}
						p{
							font-size: 0.750em;
						}
					}
				}
			}
			&.item-case-study{
				> a{
					.caption{
						padding: 20px;

						.title{
							font-size: 1.250em;
							font-weight: 300;
						}
						.architect{
							font-size: 0.750em;
						}
					}
				}
			}
			&.item-magazine{
				margin-top: 0;

				> a{
					.image{
						margin-bottom: 10px;
					}
					.caption{
						.category{
							margin-bottom: 10px;
						}
						.title{
							margin-bottom: 10px;
						}
						p{
							margin-bottom: 10px;
						}
					}
				}
			}
			&.item-new-products{
				.lbl{
					padding-left: 50px;
					margin-bottom: 10px;
					font-size: 2.000em;
					font-weight: 200;
					line-height: 1;

					> span{
						display: block;
						margin-left: -30px;
					}
				}
				.slider{
					padding-left: 20px;
					margin: 0;

					.slick{
						.slider-item{
							> a{
								padding-bottom: 60px;
								.title{
									font-size: 1.250em;
								}
								.subtitle{
									font-size: 0.750em;
									text-transform: uppercase;
								}
							}
						}
						.slick-dots{
							/*
							position: absolute;
							bottom: 0; right: 100%;
							height: auto;
							width: 12px;
							margin: 0 10px 10px 0;

							> li{
								button{
									width: 10px; height: 10px;
									margin: 10px 0;
									font-family: 'Lato', sans-serif;
									text-align: center;
									border-color: $brand-tertiary;
									color: $brand-secondary;
								}
								&.slick-active{
									button{
										background-color: $brand-primary;
										border-color: $brand-primary;
										color: $brand-primary;
									}
								}
							}
							*/
							height: 38px;
							padding: 14px 0;

							> li{
								button{
									width: 10px; height: 10px;
									margin: 0 8px;
									font-family: 'Lato', sans-serif;
									text-align: center;
									border-color: $brand-tertiary;
									color: $brand-secondary;
								}
								&.slick-active{
									button{
										background-color: $brand-primary;
										border-color: $brand-primary;
										color: $brand-primary;
									}
								}
							}
						}
					}
				}
			}
			&.item-categories{
				margin-bottom: 40px;

				> div{
					margin-bottom: 20px;

					.title{
						display: block;
						margin-bottom: 40px;
						font-family: 'Lato', sans-serif;
						font-size: 1.250em;
						font-weight: 300;
						text-transform: uppercase;
					}
					> div{
						padding: 0 0 0 20px;

						> a{
							position: relative;
							display: inline-block;
							padding: 0 20px 10px 20px;
							margin-bottom: 10px;
							text-align: center;
							color: $brand-secondary;

							&:hover, &:focus{
								text-decoration: none;
							}
							.images{
								.img-responsive{
									display: inline-block;
								}
							}
							> span{
								display: none;
								position: absolute;
								top: 100%; left: 50%;
								width: 100%;
								font-family: 'Lato', sans-serif;
								font-size: 0.875em;
								font-weight: 400;
								text-transform: uppercase;
								-ms-transform: translate(-50%, 0);
								-webkit-transform: translate(-50%, 0);
								transform: translate(-50%, 0);
							}
						}
					}
				}
			}
			&.item-highlight{
				> div{
					position: relative;

					.caption{
						position: absolute;
						bottom: 30px; left: 0;
						z-index: 20;
						width: 100%;
						padding: 0 30px;
						border: none;

						> .row{
							margin: 0;
						}
						p{
							font-weight: 400;
						}
					}
					&:before{
						content: '';
						position: absolute;
						top: 0; left: 0;
						z-index: 10;
						display: block;
						width: 100%; height: 100%;
						@include opacity(0.5);
					}
					&.caption-right{
						&.overlay-light{
							&:before{
								background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.65) 100%);
								background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%);
								background: linear-gradient(135deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#a6ffffff',GradientType=1 );
							}
						}
						&.overlay-dark{
							&:before{
								background: -moz-linear-gradient(-45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
								background: -webkit-linear-gradient(-45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
								background: linear-gradient(135deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
							}
						}
					}
					&.caption-left{
						&.overlay-light{
							&:before{
								background: -moz-linear-gradient(45deg, rgba(255,255,255,0.65) 0%, rgba(255,255,255,0) 100%);
								background: -webkit-linear-gradient(45deg, rgba(255,255,255,0.65) 0%,rgba(255,255,255,0) 100%);
								background: linear-gradient(45deg, rgba(255,255,255,0.65) 0%,rgba(255,255,255,0) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6ffffff', endColorstr='#00ffffff',GradientType=1 );
							}
						}
						&.overlay-dark{
							&:before{
								background: -moz-linear-gradient(45deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
								background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
								background: linear-gradient(45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
							}
						}
					}
					&.overlay-dark{
						color: #fff;

						.caption{
							.btn{
								color: #fff;

								&:hover{
									color: $brand-primary;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.home-slider{
		margin-bottom: 60px;

		.slick{
			.slider-item{
				.caption{
					bottom: 60px;
					padding: 0 60px;
				}
			}
		}
	}

	.home-boxes{
		.item{
			&.item-product{
				> a{
					.caption{
						padding: 40px;
					}
				}
			}
			&.item-case-study{
				> a{
					.caption{
						padding: 40px;
					}
				}
			}
			&.item-magazine{
				> a{
					.image{
						margin-bottom: 15px;
					}
					.caption{
						padding-left: 40px;

						.category{
							margin-bottom: 15px;
						}
						.title{
							margin-bottom: 15px;
						}
						p{
							margin-bottom: 15px;
						}
					}
				}
			}
			&.item-new-products{
				.lbl{
					font-size: 3.000em;
				}
				.slider{
					.slick{
						.slider-item{
							> a{
								padding: 30px 60px 60px 60px;
							}
						}
					}
				}
			}
			&.item-categories{
				padding-top: 80px;
			}
			&.item-highlight{
				> div{
					.caption{
						bottom: 60px;
						padding: 0 60px;

						.title{
							font-size: 1.250em;
						}
						p{
							font-size: 0.750em;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.home-slider{
		margin-bottom: 80px;

		.slick{
			.slider-item{
				.caption{
					bottom: 90px;
					padding: 0 120px;

					.title{
						font-size: 3.125em;
					}
					p{
						font-size: 1.250em;
					}
					.btn{
						margin-top: 40px;
						font-size: 0.875em;
					}
				}
				.image{
					.img-responsive{
						transition: transform 0.3s ease-in-out;
					}
				}
				&:hover{
					.image{
						.img-responsive{
							-ms-transform: scale(1.1, 1.1);
							-webkit-transform: scale(1.1, 1.1);
							transform: scale(1.1, 1.1);
						}
					}
				}
			}
			.slick-dots{
				/*
				width: 32px;
				margin: 0 20px 10px 0;
				*/
				height: 42px;
				padding: 14px 0;

				> li{
					button{
						width: 14px; height: 14px;
						/*
						margin: 10px 0;
						line-height: 28px;
						text-indent: 0;
						border-color: transparent;
						*/
					}
				}
			}
			.slick-prev, .slick-next{
				@include opacity(0);
				position: absolute;
				bottom: 27px;
				z-index: 100;
				font-size: 11px;
				cursor: pointer;
				display: block;
				width: 21px; height: 21px;
				line-height: 22px;
				text-align: center;
				background: #fff;
				@include border-top-radius(100%);
				@include border-bottom-radius(100%);
				color: $brand-secondary;
			}
			.slick-prev{
				left: 30px;
			}
			.slick-next{
				right: 30px;
			}
			&:hover{
				.slick-prev, .slick-next{
					@include opacity(1);
					display: block;
				}
			}
		}
	}

	.home-boxes{
		.item{
			&.item-product{
				> a{
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
					.caption{
						padding: 60px;

						.title{
							font-size: 1.875em;
						}
						p{
							font-size: 1.125em;
						}
					}
				}
			}
			&.item-case-study{
				> a{
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
					.caption{
						padding: 60px;

						.title{
							font-size: 1.500em;
						}
						.architect{
							font-size: 0.750em;
						}
					}
					.lbl{
						font-size: 1.000em;
					}
				}
			}
			&.item-magazine{
				margin-top: 0;

				> a{
					.image{
						margin-bottom: 30px;

						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
					.caption{
						padding-left: 60px;

						.category{
							margin-bottom: 20px;
							font-size: 0.750em;
						}
						.title{
							margin-bottom: 30px;
							font-size: 1.500em;
						}
						p{
							margin-bottom: 20px;
							font-size: 0.875em;
						}
						.date{
							font-size: 0.750em;
						}
					}
				}
			}
			&.item-new-products{
				margin-top: 60px;
				margin-bottom: 60px;
				
				.lbl{
					position: absolute;
					top: 140px; left: -131px;
					width: 400px;
					text-align: right;
					font-size: 3.750em;
					@include rotate(-90deg);

					> span{
						padding: 0 150px 0 0;
						margin: 0;
					}
				}
				.slider{
					padding-top: 0;
					padding-left: 110px;

					.slick{
						.slider-item{
							> a{
								padding-bottom: 60px;

								&:hover{
									.image{
										.img-responsive{
											-ms-transform: scale(1.1, 1.1);
											-webkit-transform: scale(1.1, 1.1);
											transform: scale(1.1, 1.1);
										}
									}
								}
								.image{
									.img-responsive{
										transition: transform 0.3s ease-in-out;
									}
								}
								.title{
									position: relative;
									z-index: 10;
									font-size: 1.875em;
								}
							}
						}
						.slick-dots{
							/* width: 14px;
							margin: 0 20px 10px 0; */

							> li{
								button{
									width: 14px; height: 14px;
									/*
									margin: 10px 0;
									line-height: 28px;
									text-indent: 0;
									border-color: transparent;
									*/
								}
							}
						}
						.slick-prev, .slick-next{
							@include opacity(0);
							position: absolute;
							bottom: 27px;
							z-index: 100;
							font-size: 11px;
							cursor: pointer;
							display: block;
							width: 21px; height: 21px;
							line-height: 21px;
							text-align: center;
							background: none;
							border: $brand-tertiary 1px solid;
							@include border-top-radius(100%);
							@include border-bottom-radius(100%);
							color: $brand-secondary;
						}
						.slick-prev{
							left: 30px;
						}
						.slick-next{
							right: 30px;
						}
						&:hover{
							.slick-prev, .slick-next{
								@include opacity(1);
								display: block;
							}
						}
					}
				}
			}
			&.item-categories{
				padding-top: 50px;

				> div{
					.title{
						font-size: 1.875em;
					}
					> div{
						padding: 0 0 0 20px;

						> a{
							.images{
								.img-responsive{
									transition: transform 0.3s ease-in-out;
								}
							}
							&:hover{
								color: $brand-primary;

								.images{
									.img-responsive{
										-ms-transform: scale(1.1, 1.1);
										-webkit-transform: scale(1.1, 1.1);
										transform: scale(1.1, 1.1);
									}
								}
								> span{
									display: block;
								}
							}
						}
					}
				}
			}
			&.item-highlight{
				> div{
					.caption{
						.title{
							font-size: 1.875em;
						}
						p{
							font-size: 1.125em;
						}
						.btn{
							margin-top: 40px;
							font-size: 0.875em;
						}
					}
					.image{
						overflow: hidden;

						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.home-boxes{
		.item{
			&.item-magazine{
				margin-top: 0;
			}
		}
	}
}