.product-cats-list{
	> .row{
		margin: 0 -10px;

		> div{
			padding: 0 10px;
		}
	}
	.title{
		margin: 20px 0 25px 0;
		font-size: 1.250em;
		font-weight: 400;
		text-transform: uppercase;
	}
	.item{
		margin-bottom: 20px;

		> a{
			position: relative;
			display: block;
			color: $brand-secondary;

			&:hover, &:focus{
				text-decoration: none;
			}
			.caption{
				> .row{
					margin: 0;

					> div{
						padding: 0;

						&.text{
							h2{
								margin: 5px 0 5px 0;
								font-size: 0.750em;
								font-weight: 300;
								text-transform: uppercase;
							}
						}
						&.icon{
							position: relative;

							> .icon{
								position: absolute;
								top: 0; left: 1px;
								width: 100%;
								padding: 5px;
								background: #fff;
								-ms-transform: translate(0, -50%);
								-webkit-transform: translate(0, -50%);
								transform: translate(0, -50%);
							} 
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.product-cats-list{
		margin-top: 20px;

		> .row{
			margin: 0 -20px;

			> div{
				padding: 0 20px;
			}
		}
		.item{
			margin-bottom: 40px;

			> a{
				.caption{
					> .row{
						> div{
							&.text{
								font-size: 1.250em;
							}
						}
					}
				}
			}
		}
	}
}

.products-list-top{
	margin-top: 30px;

	h1{
		margin: 0 0 0 0;
		font-size: 1.250em;
		font-weight: 400;
		text-transform: uppercase;
		color: $brand-primary;
	}
	h2{
		margin: 0 0 5px 0;
		font-size: 0.813em;
		font-weight: 300;
		text-transform: uppercase;
	}
	.caption{
		margin: 20px 0;
		font-size: 0.813em;
		font-weight: 300;
		line-height: 2;
	}
}

.products-list{
	.filters{
		margin-bottom: 30px;
		text-align: center;

		.dropdown{
			display: inline-block;

			&.open{
				.btn{
					background: #fff;

					.glyphicon{
						&:before{
							content: "\e014";
						}
					}
				}
			}
			.btn{
				padding: 20px 15px;
				font-size: 0.688em;
				letter-spacing: 0.05em;
			}
			.glyphicon{
				padding-left: 10px;
			}
			.dropdown-menu{
				width: 100%;
				margin: 0;
				background: $brand-primary;
				border: none;
				@include border-top-radius(0);
				@include border-bottom-radius(0);
				box-shadow: none;

				> li{
					margin: 10px 0;

					&.active{
						> a{
							background: none;
							color: #ccc;
						}
					}
					> a{
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						font-weight: 400;
						text-transform: uppercase;
						color: #fff;
						white-space: normal;

						&:hover, &:focus{
							background: none;
							color: #fff;

							span{
								display: inline-block;
								padding-left: 7px;
								margin-left: -9px;
								border-left: #fff 2px solid;
							}
						}
					}
				}
			}
		}
	}
	> .container{
		> .row{
			margin: 0 -10px;

			> div{
				padding: 0 10px;
			}
		}
	}
	.item{
		margin-bottom: 20px;

		> a{
			position: relative;
			display: block;
			padding: 15px 15px 50px 15px;
			color: $brand-secondary;

			&:hover, &:focus{
				text-decoration: none;
			}
			&:before{
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 100;
				display: block;
				width: 100%; height: 100%;
				background: rgba(#000, 0.03);
			}
			.image{
				margin-bottom: 15px;
				text-align: center;

				.img-responsive{
					display: inline-block;
				}
			}
			.caption{
				position: absolute;
				bottom: 0; left: 0;
				width: 100%;
				padding: 15px;
			}
			.title{
				display: block;
				margin-bottom: 5px;
				font-family: 'Lato', sans-serif;
				font-size: 0.813em;
				font-weight: 300;
				line-height: 1;
				text-transform: uppercase;

				font{
					font-size: 0.5em;
				}
			}
			.subtitle{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 0.563em;
				font-weight: 400;
			}
		}
	}
}

.products-list-design-top{
	margin-top: 20px;

	h1{
		margin: 0 0 0 0;
		font-size: 1.250em;
		font-weight: 400;
		text-transform: uppercase;
		color: $brand-primary;
	}
	h2{
		margin: 20px 0 5px 0;
		font-size: 0.813em;
		font-weight: 300;
		text-transform: uppercase;
	}
	.caption{
		margin: 20px 0;
		font-size: 0.813em;
		font-weight: 300;
		line-height: 2;
	}
}

.products-list-design{
	.filters{
		margin-bottom: 30px;
		text-align: center;

		.dropdown{
			display: inline-block;

			&.open{
				.btn{
					background: #fff;

					.glyphicon{
						&:before{
							content: "\e014";
						}
					}
				}
			}
			.btn{
				padding: 20px 15px;
				font-size: 0.688em;
				letter-spacing: 0.05em;
			}
			.glyphicon{
				padding-left: 10px;
			}
			.dropdown-menu{
				width: 100%;
				margin: 0;
				background: $brand-primary;
				border: none;
				@include border-top-radius(0);
				@include border-bottom-radius(0);
				box-shadow: none;

				> li{
					margin: 10px 0;

					&.active{
						> a{
							background: none;
							color: #ccc;
						}
					}
					> a{
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						font-weight: 400;
						text-transform: uppercase;
						color: #fff;
						white-space: normal;

						&:hover, &:focus{
							background: none;
							color: #fff;

							span{
								display: inline-block;
								padding-left: 7px;
								margin-left: -9px;
								border-left: #fff 2px solid;
							}
						}
					}
				}
			}
		}
	}
	> .container{
		> .row{
			margin: 0 -10px;

			> div{
				> div{
					padding: 0 10px;
				}
			}
		}
	}
	.item{
		margin-bottom: 20px;

		&.product{
			> a{
				position: relative;
				display: block;
				padding: 15px 15px 50px 15px;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:before{
					content: '';
					position: absolute;
					top: 0; left: 0;
					z-index: 100;
					display: block;
					width: 100%; height: 100%;
					background: rgba(#000, 0.03);
				}
				.image{
					margin-bottom: 15px;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.caption{
					position: absolute;
					bottom: 0; left: 0;
					width: 100%;
					padding: 15px;
				}
				.title{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.813em;
					font-weight: 300;
					text-transform: uppercase;
				}
				.subtitle{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.563em;
					font-weight: 400;
				}
			}
		}
		&.magazine{
			> a{
				display: block;
				position: relative;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				.image{
					overflow: hidden;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.caption{
					padding: 10px;

					.category{
						display: block;
						margin-bottom: 5px;
						font-family: 'Lato', sans-serif;
						font-size: 0.625em;
						font-weight: 300;
						text-transform: uppercase;
					}
					.title{
						display: block;
						margin-bottom: 5px;
						font-family: 'Lato', sans-serif;
						font-size: 0.875em;
						font-weight: 300;
						text-transform: uppercase;
					}
					p{
						margin: 0 0 5px 0;
						font-size: 0.750em;
						font-weight: 300;
					}
					.date{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.625em;
						font-style: italic;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
			}
		}
		&.case-study{
			> a{
				display: block;
				position: relative;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:before{
					content: '';
					position: absolute;
					bottom: 0; left: 0;
					z-index: 10;
					display: block;
					width: 100%; height: 75%;
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
				}
				.image{
					overflow: hidden;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.lbl{
					position: absolute;
					top: 0; right: 0;
					z-index: 15;
					padding: 5px;
					font-family: 'Lato', sans-serif;
					font-size: 0.625em;
					font-weight: 300;
					text-transform: uppercase;
					background: #fff;
				}
				.caption{
					position: absolute;
					bottom: 0; left: 0;
					z-index: 20;
					width: 100%;
					padding: 20px;
					border: none;
					color: #fff;

					.title{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						font-weight: 400;
						line-height: 1;
						text-transform: uppercase;
					}
					.architect{
						display: block;
						margin: 5px 0 0 0;
						font-family: 'Lato', sans-serif;
						font-size: 0.688em;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

.product-detail-top{
	position: relative;
	background: rgba(#000, 0.03);

	.product-slider{
		.slick{
			.slider-item{
				position: relative;
				background: #fff;

				&:before{
					content: '';
					position: absolute;
					top: 0; left: 0;
					display: block;
					width: 100%; height: 100%;
					z-index: 20;
					background: rgba(#000, 0.03);
				}
			}
			.slick-dots{
				position: absolute;
				bottom: 15px; left: 50%;
				height: 10px;
				padding: 0;
				margin: 25px 0 0 0;
				list-style: none;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 0 0 15px;
						text-indent: -999px;
						background: #fff;
						border: none;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
						}
					}
				}
			}
		}
	}
	.product-heading{
		position: relative;
		padding: 20px;

		h1{
			margin: 0 0 10px 0;
			font-family: 'Lato', sans-serif;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		h2{
			margin: 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;
			font-weight: 400;
			text-transform: uppercase;
		}
		.product-plus{
			padding-right: 75px;
			margin-top: 40px;

			> ul{
				padding: 0;
				margin: 0;
				list-style: none;

				> li{
					margin-top: 10px;
					font-family: 'Lato', sans-serif;
					font-size: 0.750em;
					font-weight: 400;
					text-transform: uppercase; 
				}
			}
		}
		.product-icon{
			position: absolute;
			bottom: 0; right: 0;
			width: 75px; height: 75px;
			padding: 10px;
			background: #fff;
		}
		&.no-plus{
			h1, h2{
				padding-right: 70px;
			}
		}
	}
}

.product-detail{
	.product-links{
		position: relative;
		margin: 20px 0;

		.btn{
			height: 54px;
			padding: 20px;
			font-size: 0.688em;
			outline: none;

			&.video{
				position: relative;
				padding-left: 40px;
				margin-right: 10px;

				&:before{
					content: '';
					position: absolute;
					top: 50%; left: 14px;
					display: block;
					width: 31px; height: 31px;
					background: url('../images/icon-play.png') 0 0 no-repeat;
					background-size: 31px 31px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
				&:hover{
					&:before{
						background-image: url('../images/icon-play-red.png');
					}
				}
			}
		}
		.dropdown{
			position: absolute;
			top: 0; right: 0;

			&.open{
				.btn{
					background: #fff;

					.glyphicon{
						&:before{
							content: "\e014";
						}
					}
				}
			}
			.btn{
				padding: 20px 15px;
				font-size: 0.688em;
				letter-spacing: 0.05em;
				outline: none;
			}
			.glyphicon{
				padding-left: 10px;
			}
			.dropdown-menu{
				width: 100%;
				min-width: inherit;
				margin: 0;
				background: $brand-primary;
				border: none;
				@include border-top-radius(0);
				@include border-bottom-radius(0);
				box-shadow: none;

				> li{
					margin: 10px 0;

					&.active{
						> a{
							background: none;
							color: #ccc;
						}
					}
					> a{
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						font-weight: 400;
						text-transform: uppercase;
						color: #fff;
						white-space: normal;

						&:hover, &:focus{
							background: none;
							color: #fff;

							span{
								display: inline-block;
								padding-left: 7px;
								margin-left: -9px;
								border-left: #fff 2px solid;
							}
						}
					}
				}
			}
		}
	}
	.product-technical-draw{
		text-align: center;

		.img-responsive{
			display: inline-block;
		}
	}
	.product-description{
		margin: 20px 0 40px 0;
		font-size: 0.813em;
		font-weight: 300;
		line-height: 2;
	}
	.product-technical-features,
	.product-certifications{
		margin-bottom: 20px;

		> .title{
			display: block;
			padding: 0 0 10px 0;
			margin: 0 0 20px 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.875em;
			font-weight: 400;
			text-transform: uppercase;
			border-bottom: #DFDFDF 1px solid;
		}
		.item{
			margin-bottom: 20px;
			text-align: center;

			.icon{
				margin-bottom: 15px;

				.img-responsive{
					display: inline-block;
				}
			}
			.title{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 0.688em;
				font-weight: 700;
				text-transform: uppercase;
			}
			.feature{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 0.688em;
				font-weight: 400;
				line-height: 2;
			}
		}
	}
}

.product-extras{
	.slick{
		.extra-item{
			.image{
				margin-top: 20px;
				margin-bottom: 20px;
			}
			.caption{
				margin-top: 20px;
				margin-bottom: 20px;

				.title{
					display: block;
					margin: 0 0 10px 0;
					font-family: 'Lato', sans-serif;
					font-size: 1.000em;
					font-weight: 300;
					text-transform: uppercase;
				}
				p{
					font-size: 0.813em;
					font-weight: 300;
					line-height: 2;
				}
			}
		}
		.slick-dots{
			height: 10px;
			padding: 10px 0;
			margin: 0;
			list-style: none;
			text-align: center;

			> li{
				display: inline-block;

				button{
					display: block;
					width: 10px; height: 10px;
					overflow: hidden;
					padding: 0;
					margin: 0 7px;
					text-indent: -999px;
					background: none;
					border: $brand-tertiary 1px solid;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					outline: none;
				}
				&.slick-active{
					button{
						background: $brand-primary;
						border-color: $brand-primary;
					}
				}
			}
		}
	}
}

.product-cta{
	margin: 40px 0;
	text-align: center;

	.title{
		display: block;
		margin-bottom: 15px;
		font-family: 'Lato', sans-serif;
		font-size: 1.000em;
		font-weight: 300;
		text-transform: uppercase;
	}
	.btn{
		padding: 20px;
		font-size: 0.688em;
	}
}

.product-related{
	overflow: hidden;

	> .container{
		padding-left: 0;
		padding-right: 0;
	}
	.products{
		margin-bottom: 40px;

		> .title{
			display: block;
			padding: 0 20px;
			margin-bottom: 20px;
			font-family: 'Lato', sans-serif;
			font-size: 1.000em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.slick{
			.product-item{
				padding: 0 2%;

				> a{
					position: relative;
					display: block;
					padding: 15px 15px 50px 15px;
					color: $brand-secondary;

					&:hover, &:focus{
						text-decoration: none;
					}
					&:before{
						content: '';
						position: absolute;
						top: 0; left: 0;
						z-index: 100;
						display: block;
						width: 100%; height: 100%;
						background: rgba(#000, 0.03);
					}
					.image{
						margin-bottom: 15px;
						text-align: center;

						.img-responsive{
							display: inline-block;
						}
					}
					.caption{
						position: absolute;
						bottom: 0; left: 0;
						width: 100%;
						padding: 15px;
					}
					.title{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.813em;
						font-weight: 300;
						text-transform: uppercase;
					}
					.subtitle{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.563em;
						font-weight: 400;
						text-transform: uppercase;
					}
				}
			}
			.slick-dots{
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: none;
						border: $brand-secondary 1px solid;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							border-color: $brand-primary;
						}
					}
				}
			}
		}
	}
	.case-study{
		.slick{
			.slick-dots{
				position: absolute;
				bottom: 15px; left: 50%;
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: #fff;
						border: none;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
						}
					}
				}
			}
		}
		.row{
			> div{
				> a{
					display: block;
					position: relative;
					color: $brand-secondary;

					&:hover, &:focus{
						text-decoration: none;
					}
					&:before{
						content: '';
						position: absolute;
						bottom: 0; left: 0;
						z-index: 10;
						display: block;
						width: 100%; height: 75%;
						background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
						background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
						background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
					}
					.image{
						overflow: hidden;
						text-align: center;

						.img-responsive{
							display: inline-block;
						}
					}
					.lbl{
						position: absolute;
						top: 0; right: 0;
						z-index: 15;
						padding: 5px;
						font-family: 'Lato', sans-serif;
						font-size: 0.625em;
						font-weight: 300;
						text-transform: uppercase;
						background: #fff;
					}
					.caption{
						position: absolute;
						bottom: 15px; left: 0;
						z-index: 20;
						width: 100%;
						padding: 20px;
						border: none;
						color: #fff;

						.title{
							display: block;
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
							font-weight: 400;
							line-height: 1;
							text-transform: uppercase;
						}
						.architect{
							display: block;
							margin: 5px 0 0 0;
							font-family: 'Lato', sans-serif;
							font-size: 0.688em;
							font-weight: 300;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}

#modal_finiture{
	.modal-title{
		display: none;
	}
	.modal-body{
		padding-left: 0;
		padding-right: 0;
	}
}
.slider-finiture{
	.slick{
		.slider-item{
			@include opacity(0.5);
			width: 300px;
			padding-left: 15px;
			padding-right: 15px;

			&.slick-current{
				@include opacity(1);
			}
			.image{
				margin-bottom: 20px;
				text-align: center;

				.img-responsive{
					display: inline-block;
				}
			}
			.title{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 0.875em;
				font-weight: 400;
				text-transform: uppercase;
			}
		}
		.slick-prev{
			position: absolute;
			top: 50%; left: 30px;
			z-index: 100;
			display: block;
			width: 48px; height: 48px;
			font-size: 20px;
			line-height: 48px;
			text-align: center;
			overflow: hidden;
			background: #fff;
			color: $brand-secondary;
			cursor: pointer;
			-ms-transform: translate(0, -50%);
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);
			@include border-top-radius(100%);
			@include border-bottom-radius(100%);

			&:hover{
				color: $brand-primary;
			}
		}
		.slick-next{
			position: absolute;
			top: 50%; right: 30px;
			z-index: 100;
			display: block;
			width: 48px; height: 48px;
			font-size: 20px;
			line-height: 48px;
			text-align: center;
			overflow: hidden;
			background: #fff;
			color: $brand-secondary;
			cursor: pointer;
			-ms-transform: translate(0, -50%);
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);
			@include border-top-radius(100%);
			@include border-bottom-radius(100%);

			&:hover{
				color: $brand-primary;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.product-cats-list{
		.item{
			> a{
				.caption{
					> .row{
						> div{
							&.text{
								font-size: 1.500em;
							}
						}
					}
				}
			}
		}
	}

	.products-list-top{
		h1{
			font-size: 1.875em;
			font-weight: 300;
		}
		h2{
			font-size: 1.250em;
		}
		.caption{
			font-size: 1.000em;
		}
	}
	.products-list{
		.filters{
			margin-bottom: 30px;
			text-align: right;
		}
	}

	.products-list-design-top{
		h1{
			font-size: 1.875em;
			font-weight: 300;
		}
		h2{
			font-size: 1.250em;
		}
		.caption{
			font-size: 1.000em;
		}
	}
	.products-list-design{
		.filters{
			margin-bottom: -20px;
			text-align: right;

			.dropdown{
				position: relative;
				z-index: 110;
			}
		}
		.item{
			&.item-1{
				margin-top: 40px;
			}
			&.item-3{
				margin-top: 80px;
			}
			&.magazine{
				> a{
					.image{
						margin-bottom: 10px;
					}
					.caption{
						.category{
							margin-bottom: 10px;
						}
						.title{
							margin-bottom: 10px;
						}
						p{
							margin-bottom: 10px;
						}
					}
				}
			}
			&.case-study{
				> a{
					.caption{
						padding: 20px;

						.title{
							font-size: 1.250em;
							font-weight: 300;
						}
						.architect{
							font-size: 0.750em;
						}
					}
				}
			}
		}
	}

	.product-detail{
		.product-links{
			.btn{
				&.video{
					padding-left: 53px;
				}
			}
		}
	}

	.product-related{
		margin-bottom: 60px;

		> .container{
			padding-left: 15px;
			padding-right: 15px;

			> .row{
				margin: 0 -20px;

				> .row-height{
					> div{
						padding: 0 20px;
					}
				}
			}
		}
		.products{
			margin: 0;

			.title{
				padding: 0;
			}
			.slick{
				margin: 0 -20px;

				.product-item{
					padding: 0 20px;
				}
				.slick-dots{
					height: 38px;
					padding: 14px 0;

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
		}
		.case-study{
			.slick{
				.slick-dots{
					/*
					height: 38px;
					padding: 14px 0;
					*/

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.products-list-top{
		h1{
			font-size: 3.125em;
		}
	}

	.products-list{
		.filters{
			.dropdown{
				.btn{
					font-size: 0.875em;
				}
			}
		}
		.item{
			> a{
				padding-bottom: 60px;

				.title{
					font-size: 1.250em;
				}
				.subtitle{
					font-size: 0.750em;
					text-transform: uppercase;
				}
			}
		}
	}

	.products-list-design-top{
		h1{
			font-size: 3.125em;
		}
	}

	.products-list-design{
		.filters{
			.dropdown{
				.btn{
					font-size: 0.875em;
				}
			}
		}
		.item{
			&.product{
				> a{
					padding-bottom: 60px;

					.title{
						font-size: 1.250em;
					}
					.subtitle{
						font-size: 0.750em;
						text-transform: uppercase;
					}
				}
			}
			&.magazine{
				> a{
					.image{
						margin-bottom: 15px;
					}
					.caption{
						padding-left: 40px;

						.category{
							margin-bottom: 15px;
						}
						.title{
							margin-bottom: 15px;
						}
						p{
							margin-bottom: 15px;
						}
					}
				}
			}
			&.case-study{
				> a{
					.caption{
						padding: 40px;
					}
				}
			}
		}
	}

	.product-detail-top{
		.product-slider{
			.slick{
				.slick-dots{
					/*
					position: absolute;
					top: auto;
					bottom: 0; right: 100%;
					height: auto;
					width: 12px;
					margin: 0 10px 10px 0;
					*/
					z-index: 100;
					margin: 14px 0 0 0;
					text-align: left;

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
		}
		.product-heading{
			padding: 20px 0;
		}
		.back-to-list{
			position: absolute;
			top: 20px; right: 20px;
			display: inline-block;
			padding-right: 30px;
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;
			font-weight: 400;
			line-height: 20px;
			text-transform: uppercase;
			background: url('../images/list.png') right center no-repeat;
			background-size: 20px 20px;
			color: $brand-secondary;

			&:hover, &:focus{
				text-decoration: none;
			}
			&:hover{
				@include opacity(0.75);
			}
		}
	}

	.product-detail{
		.product-description{
			margin: 0 0 20px 0;
		}
	}
}

@media (min-width: $screen-lg-min) {
	.product-cats-list{
		.item{
			> a{
				.image{
					overflow: hidden;

					.img-responsive{
						transition: transform 0.3s ease-in-out;
					}
				}
				&:hover{
					.image{
						.img-responsive{
							-ms-transform: scale(1.1, 1.1);
							-webkit-transform: scale(1.1, 1.1);
							transform: scale(1.1, 1.1);
						}
					}
				}
				.caption{
					> .row{
						> div{
							&.text{
								font-size: 1.875em;
							}
							&.icon{
								position: relative;
								z-index: 10;
							}
						}
					}
				}
			}
		}
	}
	.products-list{
		> .container{
			> .row{
				margin: 0 -20px;

				> div{
					padding: 0 20px;
				}
			}
		}
		.item{
			margin-bottom: 40px;

			> a{
				padding: 30px 30px 90px 30px;

				.caption{
					padding: 40px;
					z-index: 20;
				}
				.image{
					.img-responsive{
						transition: transform 0.3s ease-in-out;
					}
				}
				&:hover{
					.image{
						.img-responsive{
							-ms-transform: scale(1.1, 1.1);
							-webkit-transform: scale(1.1, 1.1);
							transform: scale(1.1, 1.1);
						}
					}
				}
			}
		}
		.filters{
			margin-bottom: 70px;
		}
	}


	.products-list-design{
		> .container{
			> .row{
				margin: 0 -20px;

				> div{
					> div{
						padding: 0 20px;
					}
				}
			}
		}
		.item{
			margin-bottom: 40px;

			&.product{
				> a{
					padding: 30px 30px 90px 30px;

					.caption{
						padding: 40px;
						z-index: 20;
					}
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
				}
			}
			&.magazine{
				> a{
					.image{
						margin-bottom: 30px;

						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
					.caption{
						padding-left: 60px;

						.category{
							margin-bottom: 20px;
							font-size: 0.750em;
						}
						.title{
							margin-bottom: 30px;
							font-size: 1.500em;
						}
						p{
							margin-bottom: 20px;
							font-size: 0.875em;
						}
						.date{
							font-size: 0.750em;
						}
					}
				}
			}
			&.case-study{
				> a{
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
					.caption{
						padding: 60px;

						.title{
							font-size: 1.500em;
						}
						.architect{
							font-size: 0.750em;
						}
					}
					.lbl{
						padding: 10px;
						font-size: 1.000em;
					}
				}
			}
		}
	}

	.product-detail-top{
		.product-slider{
			.slick{
				.slick-dots{
					/*
					width: 32px;
					margin: 0 20px 10px 0;
					*/

					> li{
						button{
							width: 14px; height: 14px;
							/*
							margin: 10px 0;
							line-height: 28px;
							text-indent: 0;
							border-color: transparent;
							*/
						}
					}
				}
			}
		}
		.product-heading{
			padding: 0 40px 80px 10px;

			h1{
				font-size: 2.500em;
				font-weight: 300;
			}
			.product-plus{
				padding-right: 115px;

				> ul{
					> li{
						margin-top: 20px;
					}
				}
			}
			.product-icon{
				width: 115px; height: 115px;
			}
			&.no-plus{
				h1, h2{
					padding-right: 90px;
				}
			}
		}
		.back-to-list{
			top: 40px; right: 40px;
		}
	}

	.product-detail{
		.vertical-label{
			position: relative;
			width: 100%; height: 10px;
			margin-top: 77px;
			@include rotate(-90deg);

			> span{
				position: absolute;
				top: 5px; right: 0;
				font-family: 'Lato', sans-serif;
				font-size: 4.375em;
				font-weight: 200;
				line-height: 1;
				text-align: right;
				text-transform: uppercase;
				white-space: nowrap;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
		}
		.product-links{
			margin: 40px 0;

			.btn{
				font-size: 0.875em !important;
			}
			.dropdown{
				.btn{
					padding-right: 45px;

					.glyphicon{
						position: absolute;
						top: 50%; right: 15px;
						-ms-transform: translate(0, -50%);
						-webkit-transform: translate(0, -50%);
						transform: translate(0, -50%);
					}
				}
			}
		}
		.product-description{
			margin: 0 0 60px 0;
			font-size: 1.000em;
		}
		.product-technical-features,
		.product-certifications{
			margin-bottom: 40px;

			> .title{
				font-size: 0.875em;
			}
			.item{
				.title{
					font-size: 0.750em;
				}
				.feature{
					font-size: 0.750em;
				}
			}
		}
	}

	.product-extras{
		.slick{
			.extra-item{
				.image{
					margin-top: 0;
					margin-bottom: 0;
				}
				.caption{
					margin-top: 40px;
					margin-bottom: 40px;
					
					.title{
						margin-bottom: 40px;
						font-size: 1.625em;
					}
					p{
						font-size: 1.000em;
					}
				}
			}
		}
		.item-1{
		}
		.item-2{
			margin-top: -40px;
		}
		.item-3{
			margin-top: 60px;
		}
	}

	.product-cta{
		margin: 80px 0;

		.title{
			font-size: 1.875em;
		}
		.btn{
			font-size: 0.875em;
		}
	}

	.product-related{
		.products{
			> .title{
				padding-left: 100px;
				margin-bottom: 40px;
				font-size: 1.500em;
			}
			.slick{
				.product-item{
					> a{
						padding: 30px 30px 90px 30px;

						.caption{
							padding: 40px;
							z-index: 20;

							.title{
								font-size: 1.250em;
							}
							.subtitle{
								font-size: 0.750em;
							}
						}
						.image{
							.img-responsive{
								transition: transform 0.3s ease-in-out;
							}
						}
						&:hover{
							.image{
								.img-responsive{
									-ms-transform: scale(1.1, 1.1);
									-webkit-transform: scale(1.1, 1.1);
									transform: scale(1.1, 1.1);
								}
							}
						}
					}
				}
				.slick-dots{
					height: 42px;

					> li{
						button{
							width: 14px; height: 14px;
						}
					}
				}
			}
		}
		.case-study{
			.slick{
				.slick-dots{
					bottom: 35px;
					/*
					height: 42px;
					*/

					> li{
						button{
							width: 14px; height: 14px;
						}
					}
				}
				.slick-prev, .slick-next{
					@include opacity(0);
					position: absolute;
					bottom: 27px;
					z-index: 100;
					font-size: 11px;
					cursor: pointer;
					display: block;
					width: 21px; height: 21px;
					line-height: 22px;
					text-align: center;
					background: #fff;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					color: $brand-secondary;
				}
				.slick-prev{
					left: 30px;
				}
				.slick-next{
					right: 30px;
				}
				&:hover{
					.slick-prev, .slick-next{
						@include opacity(1);
						display: block;
					}
				}
			}
			 .row{
				> div{
					> a{
						.image{
							.img-responsive{
								transition: transform 0.3s ease-in-out;
							}
						}
						&:hover{
							.image{
								.img-responsive{
									-ms-transform: scale(1.1, 1.1);
									-webkit-transform: scale(1.1, 1.1);
									transform: scale(1.1, 1.1);
								}
							}
						}
						.caption{
							padding: 60px;

							.title{
								font-size: 1.500em;
							}
							.architect{
								font-size: 0.750em;
							}
						}
						.lbl{
							padding: 10px;
							font-size: 1.000em;
						}
					}
				}
			}
		}
	}

	.product-detail-top{
		.product-slider{
			.slick{
				.slick-dots{
					bottom: 35px;
				}
				.slick-prev, .slick-next{
					@include opacity(0);
					position: absolute;
					bottom: 27px;
					z-index: 100;
					font-size: 11px;
					cursor: pointer;
					display: block;
					width: 21px; height: 21px;
					line-height: 22px;
					text-align: center;
					background: #fff;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					color: $brand-secondary;
				}
				.slick-prev{
					left: 30px;
				}
				.slick-next{
					right: 30px;
				}
				&:hover{
					.slick-prev, .slick-next{
						@include opacity(1);
						display: block;
					}
				}
			}
		}
	}

	.fixed-product-filters{
		position: fixed;
		top: 50%; right: 0;
		z-index: 1000;

		.dropdown{
			.btn{
				position: relative;
				width: 10px; height: 10px;
				padding: 0;
				margin: 0;
				background: none;
				border: none;
				@include rotate(-90deg);
				box-shadow: none;

				> span{
					position: absolute;
					bottom: 0; right: 0;
					font-family: 'Lato', sans-serif;
					font-size: 3.000em;
					font-weight: 200;
					line-height: 1;
					text-align: right;
					text-transform: uppercase;
					white-space: nowrap;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);

					&:after{
						content: '';
						position: absolute;
						top: 50%; left: 100%;
						display: block;
						width: 42px; height: 42px;
						margin-left: 15px;
						background: url('../images/icon-filter.png') 0 0 no-repeat;
						background-size: 42px 42px;
						-ms-transform: translate(0, -50%) rotate(90deg);
						-webkit-transform: translate(0, -50%) rotate(90deg);
						transform: translate(0, -50%) rotate(90deg);
					}
				}
				&:hover, &:focus{
					background: none;
					color: $brand-secondary;
					@include opacity(0.75);
					box-shadow: none;
				}
			}
		}
		.dropdown-menu{
			left: auto;
			right: 70px;
			width: 200px;
			margin: 0;
			background: $brand-primary;
			border: none;
			@include border-top-radius(0);
			@include border-bottom-radius(0);
			box-shadow: none;

			> li{
				margin: 10px 0;

				&.active{
					> a{
						background: none;
						color: #ccc;
					}
				}
				> a{
					font-family: 'Lato', sans-serif;
					font-size: 0.750em;
					font-weight: 400;
					text-transform: uppercase;
					color: #fff;
					white-space: normal;

					&:hover, &:focus{
						background: none;
						color: #fff;

						span{
							display: inline-block;
							padding-left: 7px;
							margin-left: -9px;
							border-left: #fff 2px solid;
						}
					}
				}
			}
		}
	}

	.products-list-top{
		padding-bottom: 100px;
	}

	.products-list-design-top{
		padding-bottom: 100px;
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.product-detail-top .back-to-list{
		background-image: url('../images/list@2x.png');
	}

	.product-detail .product-links .btn.video:before{
		background-image: url('../images/icon-play@2x.png');
	}
	.product-detail .product-links .btn.video:hover:before{
		background-image: url('../images/icon-play-red@2x.png');
	}

	.fixed-product-filters .dropdown .btn>span:after{
		background-image: url('../images/icon-filter@2x.png');
	}
}
