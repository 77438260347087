.magazine-list-head{
	.list-title{
		margin-top: 20px;
		margin-bottom: 30px;

		h1{
			margin: 0 0 30px 0;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		.filters{
			margin-bottom: 0;
			text-align: center;

			.dropdown{
				display: inline-block;

				&.open{
					.btn{
						background: #fff;

						.glyphicon{
							&:before{
								content: "\e014";
							}
						}
					}
				}
				.btn{
					padding: 20px 15px;
					font-size: 0.688em;
					letter-spacing: 0.05em;
				}
				.glyphicon{
					padding-left: 10px;
				}
				.dropdown-menu{
					width: 100%;
					margin: 0;
					background: $brand-primary;
					border: none;
					@include border-top-radius(0);
					@include border-bottom-radius(0);
					box-shadow: none;

					> li{
						margin: 10px 0;
						
						&.active{
							> a{
								background: none;
								color: #ccc;
							}
						}
						> a{
							font-family: 'Lato', sans-serif;
							font-size: 0.750em;
							font-weight: 400;
							text-transform: uppercase;
							color: #fff;
							white-space: normal;

							&:hover, &:focus{
								background: none;
								color: #fff;

								span{
									display: inline-block;
									padding-left: 7px;
									margin-left: -9px;
									border-left: #fff 2px solid;
								}
							}
						}
					}
				}
			}
		}
	}
	.list-highlight{
		background: rgba(#000, 0.03);

		.caption{
			padding: 20px;

			.category{
				display: block;
				margin-bottom: 5px;
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;
				font-weight: 300;
				text-transform: uppercase;
			}
			.title{
				display: block;
				margin: 0 0 10px 0;
				font-family: 'Lato', sans-serif;
				font-size: 1.000em;
				font-weight: 400;
				text-transform: uppercase;

				a{
					color: $brand-primary;

					&:hover, &:focus{
						text-decoration: none;
					}
					&:hover{
						@include opacity(0.75);
					}
				}
			}
			p{
				margin: 0 0 10px 0;
				font-size: 0.813em;
			}
			.date{
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 0.688em;
				font-style: italic;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
	}
}

.magazine-list{
	margin-top: 20px;

	> .container{
		> .row{
			margin: 0 -10px;

			> div{
				.item{
					padding: 0 10px;
					margin-bottom: 30px;

					> a{
						display: block;
						position: relative;
						color: $brand-secondary;

						&:hover, &:focus{
							text-decoration: none;
						}
						.image{
							overflow: hidden;
							text-align: center;

							.img-responsive{
								display: inline-block;
							}
						}
						.caption{
							padding: 10px;

							.category{
								display: block;
								margin-bottom: 5px;
								font-family: 'Lato', sans-serif;
								font-size: 0.625em;
								font-weight: 300;
								text-transform: uppercase;
							}
							.title{
								display: block;
								margin-bottom: 5px;
								font-family: 'Lato', sans-serif;
								font-size: 0.875em;
								font-weight: 300;
								text-transform: uppercase;
							}
							p{
								margin: 0 0 5px 0;
								font-size: 0.750em;
								font-weight: 300;
							}
							.date{
								display: block;
								font-family: 'Lato', sans-serif;
								font-size: 0.625em;
								font-style: italic;
								font-weight: 300;
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
}

.magazine-detail{
	.magazine-head{
		margin-top: 20px;
		margin-bottom: 30px;

		h1{
			margin: 0 0 15px 0;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		.subtitle{
			display: block;
			margin: 0 0 5px 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.813em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.architect{
			display: block;
			margin: 0 0 5px 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.813em;
			font-weight: 700;
			text-transform: uppercase;
		}
		.category{
			display: block;
			font-family: 'Lato', sans-serif;
			font-size: 0.813em;
			font-weight: 300;
			text-transform: uppercase;
		}
	}
	.magazine-gallery{
		margin-bottom: 30px;

		.slick{
			.slick-dots{
				position: absolute;
				bottom: 15px; left: 50%;
				z-index: 100;
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: #fff;
						border: none;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
						}
					}
				}
			}
		}
	}
	.magazine-tutorial{
		margin-bottom: 30px;

		.slick{
			.slick-dots{
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: none;
						border: $brand-secondary 1px solid;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
							border-color: $brand-primary;
						}
					}
				}
			}
		}
	}
	.magazine-tutorial{
		.slick{
			.tutorial-item{
				.caption{
					.title{
						display: block;
						margin: 10px 0;
						font-family: 'Lato', sans-serif;
						font-size: 1.000em;
						font-weight: 300;
					}
					p{
						font-family: 'Lato', sans-serif;
						font-size: 0.813em;
						font-weight: 300;
						line-height: 2;
					}
				}
			}
		}
	}
	.magazine-content{
		margin-bottom: 30px;

		p{
			margin: 0 0 30px 0;
			font-size: 0.813em;
			font-weight: 300;
			line-height: 2;

			strong{
				font-weight: 700;
			}
		}
	}
	.magazine-cta{
		margin-bottom: 30px;
		text-align: center;
	}
	.magazine-controls{
		margin-bottom: 30px;
		text-align: right;

		.back-to-list{
			display: inline-block;
			padding-right: 30px;
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;
			font-weight: 400;
			line-height: 20px;
			text-transform: uppercase;
			background: url('../images/list.png') right center no-repeat;
			background-size: 20px 20px;
			color: $brand-secondary;

			&:hover, &:focus{
				text-decoration: none;
			}
			&:hover{
				@include opacity(0.75);
			}
		}
	}
}

.magazine-related{
	.articles{
		margin-bottom: 40px;

		> .title{
			display: block;
			margin-bottom: 20px;
			font-family: 'Lato', sans-serif;
			font-size: 1.000em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.slick{
			margin: 0 -10px;

			.article-item{
				padding: 0 10px;

				> a{
					display: block;
					position: relative;
					color: $brand-secondary;

					&:hover, &:focus{
						text-decoration: none;
					}
					.image{
						overflow: hidden;
						text-align: center;

						.img-responsive{
							display: inline-block;
						}
					}
					.caption{
						padding: 10px;

						.category{
							display: block;
							margin-bottom: 5px;
							font-family: 'Lato', sans-serif;
							font-size: 0.625em;
							font-weight: 300;
							text-transform: uppercase;
						}
						.title{
							display: block;
							margin-bottom: 5px;
							font-family: 'Lato', sans-serif;
							font-size: 0.875em;
							font-weight: 300;
							text-transform: uppercase;
						}
						p{
							margin: 0 0 5px 0;
							font-size: 0.750em;
							font-weight: 300;
						}
						.date{
							display: block;
							font-family: 'Lato', sans-serif;
							font-size: 0.625em;
							font-style: italic;
							font-weight: 300;
							text-transform: uppercase;
						}
					}
				}
			}

			.slick-dots{
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: none;
						border: $brand-tertiary 1px solid;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background: $brand-primary;
							border-color: $brand-primary;
						}
					}
				}
			}
		}
	}
	.product{
		.slick{
			.slick-dots{
				position: absolute;
				bottom: 15px; left: 50%;
				z-index: 100;
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: none;
						border: $brand-tertiary 1px solid;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
							border-color: $brand-primary;
						}
					}
				}
			}
		}
		.item{
			margin-top: 20px;

			> a{
				position: relative;
				display: block;
				padding: 15px 15px 75px 15px;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:before{
					content: '';
					position: absolute;
					top: 0; left: 0;
					z-index: 100;
					display: block;
					width: 100%; height: 100%;
					background: rgba(#000, 0.03);
				}
				.image{
					margin-bottom: 15px;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.caption{
					position: absolute;
					bottom: 25px; left: 0;
					width: 100%;
					padding: 15px;
				}
				.title{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.813em;
					font-weight: 300;
					text-transform: uppercase;
				}
				.subtitle{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.563em;
					font-weight: 400;
				}
				.lbl{
					position: absolute;
					top: 0; left: 50%;
					z-index: 110;
					padding: 5px;
					font-family: 'Lato', sans-serif;
					font-size: 0.625em;
					font-weight: 300;
					text-transform: uppercase;
					background: #fff;
					-ms-transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					white-space: nowrap;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.magazine-list-head{
		.list-title{
			position: relative;
			margin-top: 50px;
			margin-bottom: 40px;

			h1{
				margin: 0;
				font-size: 2.000em;
				font-weight: 300;
			}
			.filters{
				position: absolute;
				top: 50%; right: 10px;
				z-index: 100;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
			}
		}
	}

	.magazine-list{
		margin-top: 40px;

		> .container{
			> .row{
				> div{
					.item{
						&.item-1{
							margin-top: 40px;
						}
						&.item-3{
							margin-top: 80px;
						}
						> a{
							.image{
								margin-bottom: 10px;
							}
							.caption{
								.category{
									margin-bottom: 10px;
								}
								.title{
									margin-bottom: 10px;
								}
								p{
									margin-bottom: 10px;
								}
							}
						}
					}
				}
			}
		}
	}

	.magazine-detail{
		.magazine-head{
			position: relative;
			margin-top: 50px;
			margin-bottom: 30px;
		}
		.magazine-gallery{
			.slick{
				.slick-dots{
					/*
					position: absolute;
					bottom: 0; right: 100%;
					height: auto;
					width: 12px;
					margin: 0 10px 10px 0;
					height: 38px;
					*/
					padding: 14px 0;
					text-align: left;

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
		}
	}

	.magazine-related{
		margin-top: 60px;
		margin-bottom: 60px;

		> .row{
			margin: 0 -20px;

			> .row-height{
				> div{
					padding: 0 20px;
				}
			}
		}
		.articles{
			margin: 0;

			.title{
				padding: 0;
			}
			.slick{
				margin: 0 -20px;

				.article-item{
					padding: 0 20px;

					> a{
						.image{
							margin-bottom: 10px;
						}
						.caption{
							.category{
								margin-bottom: 10px;
							}
							.title{
								margin-bottom: 10px;
							}
							p{
								margin-bottom: 10px;
							}
						}
					}
				}
				.slick-dots{
					height: 38px;
					padding: 14px 0;

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
		}
		.product{
			.slick{
				.slick-dots{
					padding: 14px 0;

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
			.item{
				> a{
					.lbl{
						left: auto; right: 0;
						font-size: 0.625em;
						-ms-transform: translate(0, 0);
						-webkit-transform: translate(0, 0);
						transform: translate(0, 0);
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.magazine-list-head{
		.list-title{
			h1{
				font-size: 3.125em;
			}
			.filters{
				right: 20px;
			}
		}
		.list-highlight{
			.caption{
				padding: 15px 40px 15px 10px;
			}
		}
	}

	.magazine-list{
		margin-top: 80px;

		> .container{
			> .row{
				margin: 0 -20px;

				> div{
					.item{
						padding: 0 20px;
						margin-bottom: 40px;

						> a{
							.image{
								margin-bottom: 15px;
							}
							.caption{
								padding-left: 40px;

								.category{
									margin-bottom: 15px;
								}
								.title{
									margin-bottom: 15px;
								}
								p{
									margin-bottom: 15px;
								}
							}
						}
					}
				}
			}
		}
	}

	.magazine-detail{
		.magazine-head{
			h1{
				font-size: 2.500em;
				font-weight: 300;
			}
			.subtitle{
				margin-bottom: 10px;
			}
			.architect{
				margin-bottom: 10px;
				font-size: 1.000em;
			}
		}
		.magazine-content{
			margin-bottom: 40px;

			p{
				margin: 0 0 40px 0;
				font-size: 1.000em;
			}
		}

		.magazine-tutorial{
			margin-top: 30px;
			margin-bottom: 0;

			.slick{
				.tutorial-item{
					margin-bottom: 60px;
					
					> .row{
						margin: 0 -20px;

						> .row-height{
							> div{
								padding: 0 20px;
							}
						}
					}
					.caption{
						.title{
							margin-bottom: 30px;
							font-size: 1.500em;
							text-transform: uppercase;
						}
						p{
							font-size: 1.000em;
						}
					}
				}
			}
	}
	}

	.magazine-related{
		.articles{
			> .title{
				margin-top: 30px;
			}
		}
		.product{
			.item{
				> a{
					padding-bottom: 85px;

					.title{
						font-size: 1.250em;
					}
					.subtitle{
						font-size: 0.750em;
						text-transform: uppercase;
					}
					.lbl{
						padding: 10px;
						font-size: 0.750em;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.magazine-list-head{
		.list-title{
			h1{
			}
		}
		.list-highlight{
			.caption{
				.category{
					margin-bottom: 15px;
				}
				.title{
					margin: 0 0 15px 0;
					font-size: 1.500em;
					font-weight: 300;
				}
				p{
					margin: 0 0 15px 0;
					font-size: 0.875em;
				}
				.date{
					font-size: 0.750em;
				}
			}
		}
	}

	.magazine-list{
		margin-top: 120px;

		> .container{
			> .row{
				> div{
					.item{
						> a{
							.image{
								margin-bottom: 30px;

								.img-responsive{
									transition: transform 0.3s ease-in-out;
								}
							}
							&:hover{
								.image{
									.img-responsive{
										-ms-transform: scale(1.1, 1.1);
										-webkit-transform: scale(1.1, 1.1);
										transform: scale(1.1, 1.1);
									}
								}
							}
							.caption{
								padding-left: 60px;

								.category{
									margin-bottom: 20px;
									font-size: 0.750em;
								}
								.title{
									margin-bottom: 30px;
									font-size: 1.500em;
								}
								p{
									margin-bottom: 20px;
									font-size: 0.875em;
								}
								.date{
									font-size: 0.750em;
								}
							}
						}
					}
				}
			}
		}
	}

	.magazine-detail{
		.magazine-head{
			padding-right: 200px;

			.back-to-list{
				position: absolute;
				top: 10px; right: 0;
				display: inline-block;
				padding-right: 30px;
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;
				font-weight: 400;
				line-height: 20px;
				text-transform: uppercase;
				background: url('../images/list.png') right center no-repeat;
				background-size: 20px 20px;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:hover{
					@include opacity(0.75);
				}
			}
		}
		.magazine-gallery{
			.slick{
				.slick-dots{
					/*
					width: 32px;
					margin: 0 20px 10px 0;
					height: 42px;
					*/
					bottom: 35px;

					> li{
						button{
							width: 14px; height: 14px;
							/*
							margin: 10px 0;
							line-height: 28px;
							text-indent: 0;
							border-color: transparent;
							*/
						}
					}
				}
				.slick-prev, .slick-next{
					@include opacity(0);
					position: absolute;
					bottom: 27px;
					z-index: 100;
					font-size: 11px;
					cursor: pointer;
					display: block;
					width: 21px; height: 21px;
					line-height: 22px;
					text-align: center;
					background: #fff;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					color: $brand-secondary;
				}
				.slick-prev{
					left: 30px;
				}
				.slick-next{
					right: 30px;
				}
				&:hover{
					.slick-prev, .slick-next{
						@include opacity(1);
						display: block;
					}
				}
			}
		}
	}



	.magazine-related{
		.articles{
			> .title{
				padding-left: 60px;
				margin-top: 30px;
				margin-bottom: 40px;
				font-size: 1.500em;
			}
			.slick{
				.article-item{
					> a{
						.image{
							margin-bottom: 30px;

							.img-responsive{
								transition: transform 0.3s ease-in-out;
							}
						}
						&:hover{
							.image{
								.img-responsive{
									-ms-transform: scale(1.1, 1.1);
									-webkit-transform: scale(1.1, 1.1);
									transform: scale(1.1, 1.1);
								}
							}
						}
						.caption{
							padding-left: 60px;

							.category{
								margin-bottom: 20px;
								font-size: 0.750em;
							}
							.title{
								margin-bottom: 30px;
								font-size: 1.500em;
							}
							p{
								margin-bottom: 20px;
								font-size: 0.875em;
							}
							.date{
								font-size: 0.750em;
							}
						}
					}
				}
				.slick-dots{
					height: 42px;

					> li{
						button{
							width: 14px; height: 14px;
						}
					}
				}
			}
		}
		.product{
			.slick{
				.slick-dots{
					> li{
						button{
							width: 14px; height: 14px;
						}
					}
				}
			}
			.item{
				> a{
					padding: 30px 30px 115px 30px;

					.caption{
						padding: 40px;
						z-index: 20;
					}
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
				}
			}
		}
	}
}
