body{
	padding-top: 60px;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: $brand-secondary;
}

h1, h2, h3, h4, h5, h6{
	font-family: 'Lato', sans-serif;
	font-weight: 300;
}

::selection {
	background: $brand-primary;
	color: #fff;
	@include opacity(1);
}
::-moz-selection {
	background: $brand-primary;
	color: #fff;
	@include opacity(1);
}

textarea{
	resize: none;
}

.form-control{
	font-family: 'Lato', sans-serif;
	font-size: 0.875em;
	border-color: $brand-tertiary;
	@include border-top-radius(0);
	@include border-bottom-radius(0);

	&::-webkit-input-placeholder {
		color: $brand-secondary;
	}
	&:-moz-placeholder{
		color: $brand-secondary;
		opacity: 1;
	}
	&::-moz-placeholder{
		color: $brand-secondary;
		opacity: 1;
	}
	&:-ms-input-placeholder{
		color: $brand-secondary;
	}
	&::-ms-input-placeholder{
		color: $brand-secondary;
	}
}

.btn{
	padding: 13px 21px;
	font-family: 'Lato', sans-serif;
	font-size: 0.875em;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;
	@include border-top-radius(0);
	@include border-bottom-radius(0);

	&.btn-lg{
		padding: 21px 34px;
	}
	&.btn-default{
		background: #fff;
		border-color: #9B9B9B;

		&:focus, &:hover{
			background: #fff;
			border-color: #9B9B9B;
		}
		&:hover{
			color: $brand-primary;
		}
	}
	&.btn-primary{
		border: none;

		&.border{
			background: none;
			border: $brand-primary 1px solid;
			color: $brand-primary;
		}
	}
	&.btn-secondary{
		background: $brand-secondary;
		border: none;
		color: #fff;

		&:hover{
			background: lighten($brand-secondary, 10%);
		}
		&.border{
			background: none;
			border: $brand-secondary 1px solid;
			color: $brand-secondary;
		}
	}
	&.btn-white{
		&.border{
			background: none;
			border: #fff 2px solid;
			color: #fff;
		}
	}
}

.modal-backdrop{
	background: #fff;
}
.modal{
	color: $brand-secondary;

	.modal-content{
		background: $brand-secondary;
		border: none;
		color: #fff;
		@include border-top-radius(0);
		@include border-bottom-radius(0);

		.modal-header{
			padding: 30px 30px 15px 30px;
			border: none;

			.close{
				position: absolute;
				top: 0; right: 0;
				z-index: 100;
				width: 48px; height: 48px;
				font-size: 40px;
				line-height: 48px;
				text-align: center;
				text-shadow: none;
				background: #fff;
				color: $brand-secondary;
				@include opacity(1);
				outline: none;

				&:hover{
					color: $brand-primary;
				}
			}
			.modal-title{
				font-size: 1.250em;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
		.modal-body{
			padding: 0 30px 30px 30px;
			font-size: 0.875em;

			.areariservata-login{
				p{
					font-size: 0.786em;
					line-height: 2;
					color: #818181;
				}
				form, .form{
					margin-top: 30px;

					label{
						font-weight: 400;
					}
					.form-control{
						box-shadow: none;
						height: auto;
						padding: 20px;
						background: none;
						border-color: $brand-tertiary;
						color: #fff;

						&::-webkit-input-placeholder {
							color: #fff;
						}
						&:-moz-placeholder{
							color: #fff;
						}
						&::-moz-placeholder{
							color: #fff;
						}
						&:-ms-input-placeholder{
							color: #fff;
						}
						&::-ms-input-placeholder{
							color: #fff;
						}
					}
					.btn-link{
						margin: 15px 0 0 -15px;
						font-size: 0.786em;
						text-decoration: underline;
						color: #fff;
					}
					.btn-primary{
						float: right;
					}
					.login-links{
						a{
							color: #ccc;
						}
					}
				}
			}
			.modal-form{
				margin-top: 30px;

				.form-group{
					margin-bottom: 30px;

					.form-control{
						box-shadow: none;
						height: auto;
						padding: 20px;
						background: none;
						border-color: $brand-tertiary;
						color: #fff;

						&::-webkit-input-placeholder {
							color: #fff;
						}
						&:-moz-placeholder{
							color: #fff;
						}
						&::-moz-placeholder{
							color: #fff;
						}
						&:-ms-input-placeholder{
							color: #fff;
						}
						&::-ms-input-placeholder{
							color: #fff;
						}
					}
					.SumoSelect{
						width: 100%;

						> .CaptionCont{
							padding: 20px;
							font-family: 'Lato', sans-serif;
							font-size: 0.875em;
							background: none;
							border-color: $brand-tertiary;
							@include border-top-radius(0);
							@include border-bottom-radius(0);

							> span{
								cursor: pointer;
							}
							> label{
								display: inline-block;
								font-family: 'Glyphicons Halflings';
								font-style: normal;
								font-weight: 400;
								line-height: 1;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;

								&:before{
									position: absolute;
									top: 50%; left: 0;
									content: "\002b";
									-ms-transform: translate(0, -50%);
									-webkit-transform: translate(0, -50%);
									transform: translate(0, -50%);
								}
								i{
									display: none;
								}
							}
							> .search-txt{
								padding: 20px;
								font-family: 'Lato', sans-serif;
								background: none;
								border-color: $brand-tertiary;
								@include border-top-radius(0);
								@include border-bottom-radius(0);
							}
						}
						&.open{
							> .CaptionCont{
								> label{
									&:before{
										content: "\e014";
									}
								}
							}
							> .optWrapper{
								top: 59px;
								background: $brand-primary;
								@include border-top-radius(0);
								@include border-bottom-radius(0);
								border: none;
								box-shadow: none;

								> .options{
									> li{
										&.opt{
											padding: 10px 20px;
											border: none;
											color: #fff;

											label{
												display: block;
											}
											&.selected{
												color: #ccc;
											}
											&:hover{
												background: none;
												color: #fff;

												label{
													padding-left: 7px;
													margin-left: -9px;
													border-left: #fff 2px solid;
												}
											}
											&.placeholder{
												display: none;
											}
										}
									}
								}
							}
							.no-match{
								padding: 20px;
								color: #fff;
							}
						}
					}
				}
				.checkbox{
					margin-top: 0;
					font-family: 'Lato', sans-serif;
					font-size: 0.750em;

					a{
						color: #fff;
						text-decoration: underline;
					}
				}
				.btn{
					padding: 20px;
				}
			}
			.modal-table{
				padding: 0 0 0 0;
				margin: 0 -30px -60px -30px;
				background: #fff;
				color: $brand-secondary;

				.table-filters{
					padding: 20px;
					text-align: center;
					background: $brand-secondary;
					color: #fff;

					.dropdown{
						display: inline-block;

						.dropdown-menu{
							width: 100%;
							margin: 0;
							background: $brand-primary;
							border: none;
							@include border-top-radius(0);
							@include border-bottom-radius(0);
							box-shadow: none;

							> li{
								margin: 10px 0;

								&.active{
									> a{
										background: none;
										color: #ccc;
									}
								}
								> a{
									font-family: 'Lato', sans-serif;
									font-size: 0.750em;
									font-weight: 400;
									text-transform: uppercase;
									color: #fff;
									white-space: normal;

									&:hover, &:focus{
										background: none;
										color: #fff;

										span{
											display: inline-block;
											padding-left: 7px;
											margin-left: -9px;
											border-left: #fff 2px solid;
										}
									}
								}
							}
						}
					}
					.btn{
						position: relative;
						padding-right: 45px;
						font-size: 0.750em;
						font-weight: 400;
						background: none;
						border: none;
						color: #fff;

						&:after{
							content: '';
							position: absolute;
							top: 50%; right: 15px;
							display: block;
							width: 24px; height: 24px;
							background: url('../images/icon-order.png') center center no-repeat;
							background-size: 18px 12px;
							-ms-transform: translate(0, -50%);
							-webkit-transform: translate(0, -50%);
							transform: translate(0, -50%);
						}
					}
				}
				.table{
					thead{
						tr{
							th{
								padding: 20px 15px;
								font-family: 'Lato', sans-serif;
								font-size: 0.875em;
								font-weight: 700;
								text-transform: uppercase;
								color: #fff;
								background: $brand-secondary;
								border: none;
								color: #fff;

								&.item-size, &.item-date, &.item-link, &.item-preview{
									text-align: center;
								}
							}
						}
					}
					tbody{
						tr{
							td{
								padding: 20px 15px;
								font-family: 'Lato', sans-serif;
								font-size: 1.000em;
								border-bottom: none;
								border-right: none;
								border-top: #D9D9D9 1px solid;
								border-left: none;
								vertical-align: middle;

								&.item-size, &.item-date, &.item-link, &.item-preview{
									text-align: center;
								}
								.btn{
									padding: 5px 10px;
									font-size: 0.688em;
									font-weight: 400;
									border: $brand-primary 2px solid;
								}
								.item-name{
									display: block;
									margin-bottom: 5px;
									font-family: 'Lato', sans-serif;
									font-size: 0.813em;
									font-weight: 700;
									line-height: 1;
								}
								.item-category{
									display: block;
									font-family: 'Lato', sans-serif;
									font-size: 0.750em;
								}
								.item-size{
									display: inline-block;
									margin-right: 10px;
									font-family: 'Lato', sans-serif;
									font-size: 0.750em;
									font-size: 0.625em;
								}
								.item-date{
									display: inline-block;
									font-family: 'Lato', sans-serif;
									font-size: 0.750em;
									font-size: 0.625em;
								}
								.item-preview-mobile{
									margin-top: 15px;
									text-align: center;

									.img-responsive{
										display: inline-block;
									}
								}
								&.item-preview{
									.img-responsive{
										display: inline-block;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.video{
		.modal-content{
			.modal-header{
				padding: 0;
			}
			.modal-body{
				padding: 0;
			}
		}
	}
}

.full-height{
	height: 100%;
}

@media (min-width: $screen-sm-min) {
	body{
		padding-top: 141px;
	}
	.modal{
		text-align: center;
		padding: 0 !important;
		color: $brand-secondary;

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -4px;
		}
		.modal-dialog {
			display: inline-block;
			text-align: left;
			vertical-align: middle;
		}
		.modal-content{
			.modal-header{
				padding: 60px 60px 15px 60px;
			}
			.modal-body{
				padding: 0 60px 60px 60px;

				.areariservata-login{
					p{
						font-size: 0.857em;
					}
					form, .form{
						.btn-link{
							margin-top: 0;
						}
					}
				}
				.modal-table{
					margin: 0 -60px -60px -60px;

					.table{
						margin: 0;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.modal{
		.modal-content{
			.modal-body{
				.modal-table{
					.table-filters{
						text-align: right;
					}
					.table{
						border: none;

						tbody{
							tr{
								td{
									&.item-category, &.item-size, &.item-date, &.item-link{
										border-right: #d9d9d9 1px solid;
									}
									&.item-category, &.item-size, &.item-date{
										font-size: 0.750em;
									}
									&.item-category{
									}
									&.item-size, &.item-date, &.item-link, &.item-preview{
										width: 130px;
									}
									.item-name{
										margin: 0;
										font-size: 0.875em;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.btn{
		&.border{
			position: relative;
			z-index: 1;
			-webkit-transition: color 0.3s;
			-moz-transition: color 0.3s;
			transition: color 0.3s;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				width: 0%;
				height: 100%;
				top: 0;
				left: 0;
				-webkit-transition: width 0.3s;
				-moz-transition: width 0.3s;
				transition: width 0.3s;
			}
			&:hover:after{
				width: 100%;
			}
			&.btn-primary{
				&:after{
					background: $brand-primary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-secondary{
				&:after{
					background: $brand-secondary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-white{
				&:after{
					background: #fff;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: $brand-secondary;
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.container{
		width: 1370px;
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
}
