.case-studies-list{
	> .row{
		margin: 0 -10px;

		> div{
			> div{
				padding: 0 10px;
			}
		}
	}
	.item{
		margin-bottom: 20px;

		&.item-title{
			margin-top: 20px;
			margin-bottom: 30px;

			h1{
				margin: 0 0 30px 0;
				font-size: 1.250em;
				font-weight: 400;
				text-transform: uppercase;
				color: $brand-primary;
			}
			.filters{
				margin-bottom: 0;
				text-align: center;

				.dropdown{
					display: inline-block;

					&.open{
						.btn{
							background: #fff;

							.glyphicon{
								&:before{
									content: "\e014";
								}
							}
						}
					}
					.btn{
						padding: 20px 15px;
						font-size: 0.688em;
						letter-spacing: 0.05em;
					}
					.glyphicon{
						padding-left: 10px;
					}
					.dropdown-menu{
						width: 100%;
						margin: 0;
						background: $brand-primary;
						border: none;
						@include border-top-radius(0);
						@include border-bottom-radius(0);
						box-shadow: none;

						> li{
							margin: 10px 0;

							&.active{
								> a{
									background: none;
									color: #ccc;
								}
							}
							> a{
								font-family: 'Lato', sans-serif;
								font-size: 0.750em;
								font-weight: 400;
								text-transform: uppercase;
								color: #fff;
								white-space: normal;

								&:hover, &:focus{
									background: none;
									color: #fff;

									span{
										display: inline-block;
										padding-left: 7px;
										margin-left: -9px;
										border-left: #fff 2px solid;
									}
								}
							}
						}
					}
				}
			}
		}
		&.item-highlight{
			> div{
				background: rgba(#000, 0.03);
			}
			.caption{
				padding: 20px;

				.category{
					display: block;
					margin-bottom: 5px;
					font-family: 'Lato', sans-serif;
					font-size: 0.750em;
					font-weight: 300;
					text-transform: uppercase;
				}
				.title{
					display: block;
					margin: 0 0 10px 0;
					font-family: 'Lato', sans-serif;
					font-size: 1.000em;
					font-weight: 400;
					text-transform: uppercase;

					a{
						color: $brand-primary;

						&:hover, &:focus{
							text-decoration: none;
						}
						&:hover{
							@include opacity(0.75);
						}
					}
				}
				p{
					margin: 0 0 10px 0;
					font-size: 0.813em;
				}
				.date{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.688em;
					font-style: italic;
					font-weight: 300;
					text-transform: uppercase;
				}
			}
		}
		&.item-case-study{
			> a{
				display: block;
				position: relative;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:before{
					content: '';
					position: absolute;
					bottom: 0; left: 0;
					z-index: 10;
					display: block;
					width: 100%; height: 75%;
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
				}
				.image{
					overflow: hidden;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.lbl{
					position: absolute;
					top: 0; right: 0;
					z-index: 15;
					padding: 5px;
					font-family: 'Lato', sans-serif;
					font-size: 0.625em;
					font-weight: 300;
					text-transform: uppercase;
					background: #fff;
				}
				.caption{
					position: absolute;
					bottom: 0; left: 0;
					z-index: 20;
					width: 100%;
					padding: 20px;
					border: none;
					color: #fff;

					.title{
						display: block;
						font-family: 'Lato', sans-serif;
						font-size: 0.750em;
						font-weight: 400;
						line-height: 1;
						text-transform: uppercase;
					}
					.architect{
						display: block;
						margin: 5px 0 0 0;
						font-family: 'Lato', sans-serif;
						font-size: 0.688em;
						font-weight: 300;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

.case-study-detail{
	.case-study-head{
		margin-top: 20px;
		margin-bottom: 30px;

		h1{
			margin: 0 0 15px 0;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		.subtitle{
			display: block;
			margin: 0 0 5px 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.813em;
			font-weight: 300;
			text-transform: uppercase;
		}
		.architect{
			display: block;
			margin: 0 0 5px 0;
			font-family: 'Lato', sans-serif;
			font-size: 0.813em;
			font-weight: 700;
			text-transform: uppercase;
		}
		.category{
			display: block;
			font-family: 'Lato', sans-serif;
			font-size: 0.813em;
			font-weight: 300;
			text-transform: uppercase;
		}
	}
	.case-study-gallery{
		margin-bottom: 30px;

		.slick{
			.slick-dots{
				position: absolute;
				bottom: 15px; left: 50%;
				z-index: 100;
				height: 10px;
				padding: 10px 0;
				margin: 0;
				list-style: none;
				text-align: center;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);

				> li{
					display: inline-block;

					button{
						display: block;
						width: 10px; height: 10px;
						overflow: hidden;
						padding: 0;
						margin: 0 7px;
						text-indent: -999px;
						background: #fff;
						border: none;
						@include border-top-radius(100%);
						@include border-bottom-radius(100%);
						outline: none;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
						}
					}
				}
			}
		}
	}
	.case-study-content{
		margin-bottom: 30px;

		p{
			margin: 0 0 30px 0;
			font-size: 0.813em;
			font-weight: 300;
			line-height: 2;

			strong{
				font-weight: 700;
			}
		}
	}
	.case-study-controls{
		margin-bottom: 30px;
		text-align: right;

		.back-to-list{
			display: inline-block;
			padding-right: 30px;
			font-family: 'Lato', sans-serif;
			font-size: 0.750em;
			font-weight: 400;
			line-height: 20px;
			text-transform: uppercase;
			background: url('../images/list.png') right center no-repeat;
			background-size: 20px 20px;
			color: $brand-secondary;

			&:hover, &:focus{
				text-decoration: none;
			}
			&:hover{
				@include opacity(0.75);
			}
		}
	}
}

.case-study-related{
	margin: 0 -15px 30px -15px;

	> .title{
		display: block;
		padding: 0 20px;
		margin-bottom: 20px;
		font-family: 'Lato', sans-serif;
		font-size: 1.000em;
		font-weight: 300;
		text-transform: uppercase;
	}
	.slick{
		.product-item{
			padding: 0 2%;

			> a{
				position: relative;
				display: block;
				padding: 15px 15px 50px 15px;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:before{
					content: '';
					position: absolute;
					top: 0; left: 0;
					z-index: 100;
					display: block;
					width: 100%; height: 100%;
					background: rgba(#000, 0.03);
				}
				.image{
					margin-bottom: 15px;
					text-align: center;

					.img-responsive{
						display: inline-block;
					}
				}
				.caption{
					position: absolute;
					bottom: 0; left: 0;
					width: 100%;
					padding: 15px;
				}
				.title{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.813em;
					font-weight: 300;
					text-transform: uppercase;
				}
				.subtitle{
					display: block;
					font-family: 'Lato', sans-serif;
					font-size: 0.563em;
					font-weight: 400;
					text-transform: uppercase;
				}
			}
		}
		.slick-dots{
			height: 10px;
			padding: 10px 0;
			margin: 0;
			list-style: none;
			text-align: center;

			> li{
				display: inline-block;

				button{
					display: block;
					width: 10px; height: 10px;
					overflow: hidden;
					padding: 0;
					margin: 0 7px;
					text-indent: -999px;
					background: none;
					border: $brand-secondary 1px solid;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					outline: none;
				}
				&.slick-active{
					button{
						border-color: $brand-primary;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.case-studies-list{
		.item{
			&.item-title{
				position: relative;
				margin-top: 50px;
				margin-bottom: 40px;

				h1{
					margin: 0;
					font-size: 2.000em;
					font-weight: 300;
				}
				.filters{
					position: absolute;
					top: 50%; right: 10px;
					z-index: 100;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
			}
			&.item-margin{
				margin-top: 40px;
			}
			&.item-case-study{
				> a{
					.caption{
						padding: 20px;

						.title{
							font-size: 1.250em;
							font-weight: 300;
						}
						.architect{
							font-size: 0.750em;
						}
					}
				}
			}
		}
	}

	.case-study-detail{
		.case-study-head{
			position: relative;
			margin-top: 50px;
			margin-bottom: 30px;
		}
		.case-study-gallery{
			.slick{
				.slick-dots{
					/*
					position: absolute;
					bottom: 0; right: 100%;
					height: auto;
					width: 12px;
					margin: 0 10px 10px 0;
					*/
					/*height: 38px;*/
					padding: 14px 0;
					text-align: left;

					> li{
						button{
							width: 10px; height: 10px;
							margin: 0 8px;
							font-family: 'Lato', sans-serif;
							text-align: center;
							border-color: $brand-tertiary;
							color: $brand-secondary;
						}
						&.slick-active{
							button{
								background-color: $brand-primary;
								border-color: $brand-primary;
								color: $brand-primary;
							}
						}
					}
				}
			}
		}
	}

	.case-study-related{
		margin: 0 0 60px 0;
		
		.title{
			padding: 0;
		}
		.slick{
			margin: 0 -20px;

			.product-item{
				padding: 0 20px;
			}
			.slick-dots{
				height: 38px;
				padding: 14px 0;

				> li{
					button{
						width: 10px; height: 10px;
						margin: 0 8px;
						font-family: 'Lato', sans-serif;
						text-align: center;
						border-color: $brand-tertiary;
						color: $brand-secondary;
					}
					&.slick-active{
						button{
							background-color: $brand-primary;
							border-color: $brand-primary;
							color: $brand-primary;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.case-studies-list{
		> .row{
			margin: 0 -20px;

			> div{
				> div{
					padding: 0 20px;
				}
			}
		}
		.item{
			margin-bottom: 40px;

			&.item-title{
				h1{
					font-size: 3.125em;
				}
				.filters{
					right: 20px;
				}
			}
			&.item-highlight{
				.caption{
					padding: 15px 40px 15px 10px;
				}
			}
			&.item-case-study{
				> a{
					.caption{
						padding: 40px;
					}
				}
			}
		}
	}

	.case-study-detail{
		.case-study-head{
			h1{
				font-size: 2.500em;
				font-weight: 300;
			}
			.subtitle{
				margin-bottom: 10px;
			}
			.architect{
				margin-bottom: 10px;
				font-size: 1.000em;
			}
		}
		.case-study-content{
			margin-bottom: 40px;

			p{
				margin: 0 0 40px 0;
				font-size: 1.000em;
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.case-studies-list{
		.item{
			&.item-title{
				h1{
					padding-left: 30px;
				}
			}
			&.item-highlight{
				.caption{
					.category{
						margin-bottom: 15px;
					}
					.title{
						margin: 0 0 15px 0;
						font-size: 1.500em;
						font-weight: 300;
					}
					p{
						margin: 0 0 15px 0;
						font-size: 0.875em;
					}
					.date{
						font-size: 0.750em;
					}
				}
			}
			&.item-case-study{
				> a{
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
					.caption{
						padding: 60px;

						.title{
							font-size: 1.500em;
						}
						.architect{
							font-size: 0.750em;
						}
					}
					.lbl{
						padding: 10px;
						font-size: 1.000em;
					}
				}
			}
		}
	}

	.case-study-detail{
		.case-study-head{
			padding-right: 200px;

			.back-to-list{
				position: absolute;
				top: 10px; right: 0;
				display: inline-block;
				padding-right: 30px;
				font-family: 'Lato', sans-serif;
				font-size: 0.750em;
				font-weight: 400;
				line-height: 20px;
				text-transform: uppercase;
				background: url('../images/list.png') right center no-repeat;
				background-size: 20px 20px;
				color: $brand-secondary;

				&:hover, &:focus{
					text-decoration: none;
				}
				&:hover{
					@include opacity(0.75);
				}
			}
		}
		.case-study-gallery{
			.slick{
				.slick-dots{
					/*
					width: 32px;
					margin: 0 20px 10px 0;
					*/
					/* height: 42px; */
					bottom: 35px;

					> li{
						button{
							width: 14px; height: 14px;
							/*
							margin: 10px 0;
							line-height: 28px;
							text-indent: 0;
							border-color: transparent;
							*/
						}
					}
				}
				.slick-prev, .slick-next{
					@include opacity(0);
					position: absolute;
					bottom: 27px;
					z-index: 100;
					font-size: 11px;
					cursor: pointer;
					display: block;
					width: 21px; height: 21px;
					line-height: 22px;
					text-align: center;
					background: #fff;
					@include border-top-radius(100%);
					@include border-bottom-radius(100%);
					color: $brand-secondary;
				}
				.slick-prev{
					left: 30px;
				}
				.slick-next{
					right: 30px;
				}
				&:hover{
					.slick-prev, .slick-next{
						@include opacity(1);
						display: block;
					}
				}
			}
		}
	}

	.case-study-related{
		> .title{
			padding-left: 100px;
			margin-bottom: 40px;
			font-size: 1.500em;
		}
		.slick{
			.product-item{
				> a{
					padding: 30px 30px 90px 30px;

					.caption{
						padding: 40px;
						z-index: 20;

						.title{
							font-size: 1.250em;
						}
						.subtitle{
							font-size: 0.750em;
						}
					}
					.image{
						.img-responsive{
							transition: transform 0.3s ease-in-out;
						}
					}
					&:hover{
						.image{
							.img-responsive{
								-ms-transform: scale(1.1, 1.1);
								-webkit-transform: scale(1.1, 1.1);
								transform: scale(1.1, 1.1);
							}
						}
					}
				}
			}
			.slick-dots{
				height: 42px;

				> li{
					button{
						width: 14px; height: 14px;
					}
				}
			}
		}
		
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.case-study-detail .case-study-head .back-to-list,
	.case-study-detail .case-study-controls .back-to-list{
		background-image: url('../images/list@2x.png');
	}
}
